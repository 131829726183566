import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";


import  {  MaskedInput } from 'react-text-mask';

const StepTwo = ({register, errors, control}) => {
    


    
    return(
        <div className="application-modal_step" key={1}>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Email адрес:</span>
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (

                    <TextField
                        {...field}
                        size="small" 
                        type="email" 
                        {...register('contactEmail', { required: true })}
                        error={!!errors.contactEmail}
                        helperText={errors.contactEmail && "Обязательное поле"}
                        InputProps={{
                        // eslint-disable-next-line
                        inputComponent: MaskedInput as any,
                        inputProps: {
                            mask: [/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i],
                        },
                        }}
                        
                        placeholder="your@mail.by"
                    />
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Город проживания:</span>
                <TextField 
                    size="small" 
                    {...register('city', { required: true })}
                    error={!!errors.city}
                    helperText={errors.city && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Ссылки на социальные сети:</span>
                <TextField 
                    size="small" 
                    {...register('socialLink', { required: true })}
                    error={!!errors.socialLink}
                    helperText={errors.socialLink && "Обязательное поле"}
                />
            </FormControl>

        
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Образование:</span>
                <TextField 
                    size="small" 
                    {...register('education', { required: true })}
                    error={!!errors.education}
                    helperText={errors.education && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Текущая деятельность:</span>
                <TextField 
                    size="small" 
                    placeholder="Место работы/учёбы" 
                    {...register('workPlace', { required: true })}
                    error={!!errors.workPlace}
                    helperText={errors.workPlace && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <TextField 
                    size="small" 
                    placeholder="Специализация" 
                    {...register('specialization', { required: true })}
                    error={!!errors.specialization}
                    helperText={errors.specialization && "Обязательное поле"}
                />
            </FormControl>
        </div>
    );   
}

export default StepTwo;