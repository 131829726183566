import { useState } from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";

import StepOne from "./StepOne"; 
import StepTwo from "./StepTwo";

import ProgresBar from "../FormComponents/ProgresBar";
import NavigationButtons from "../FormComponents/NavigationButtons";
import StepLoading from "../FormComponents/StepLoading";
import StepSuccess from "../FormComponents/StepSuccess";
import StepWrong from "../FormComponents/StepWrong";

import '../style_form.scss';
import FormHeader from "../FormComponents/FormHeader";
import StepThree from "./StepThree";
import StepFour from "./StepFour";


interface Inputs {
    appliactionType: string,
    agreeRules: boolean,
    applicationDirection: string,
    fullName: string,
    contactPhone: string,
    contactEmail: string,
    cv: string,
    freeTime: string,
    motivateText: string,
    
}

const defaultValues: Inputs = {
    appliactionType: 'webvolunteer',
    agreeRules: false,
    applicationDirection: '',
    fullName: '',
    contactPhone: '',
    contactEmail: '',
    cv: '',
    freeTime: '',
    motivateText: ''
}; 

const WebVolForm = () => {

    const applicationName="Заявка Web-волонтёр";

    const { control, register, handleSubmit, formState: { errors } } = useForm<Inputs>({defaultValues});

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://vivabraslav.by/prod-api/applications.php',
            data : dataSend
        };

        const sendToServer = () => {
            dataSend.append('data', JSON.stringify(data));
            dataSend.append('g-recaptcha-response', "test");

            axios(config)
            .then(function () {
                setStep({ ...step, actual: 6 });
            })
            .catch(function () {
                setStep({ ...step, actual: 7 });
            });
        }

        if(step.actual === step.max || step.actual === 7 || step.actual === 6) {
            setStep({ ...step, actual: 5 });
            sendToServer();
        } else {
            setStep({ ...step, actual: step.actual + 1 });
        }

    }

    const [step, setStep] = useState({
        actual: 0,
        max: 1
    });

    const changeStep = () => {
        if(step.actual > 0) {
            setStep({
                ...step,
                actual: step.actual - 1
            });
        }
    }

    const getStep = () => {
        switch (step.actual) {
            case 0:
                return(
                    <StepOne
                        register={register} 
                        errors={errors}
                        control={control}
                    />
                );    
            case 1:
                return(
                    <StepTwo
                        register={register} 
                        errors={errors}
                        control={control}
                    />
                ); 
            case 2:
                return(
                    <StepThree
                        register={register} 
                        errors={errors}
                        control={control}
                    />
                );         
            case 3:
                return(
                    <StepFour
                        register={register} 
                        errors={errors}
                        control={control}
                    />
                );         
            case 5: 
                return(<StepLoading key={6}/>);
            case 6: 
                return(<StepSuccess key={7}/>);    
            case 7: 
                return(<StepWrong key={8}/>);     
        }
    }

    return(
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            <form className="application-modal_steps" onSubmit={handleSubmit(onSubmit)}>
                {getStep()}
                <div className="application-modal_navigation">
                    <NavigationButtons 
                        stepInfo={step} 
                        stepPrev={changeStep} 
                    />
                    <ProgresBar stepInfo={step}/>
                </div>
            </form>   
        </div>
    );
}

export default WebVolForm;