import { useState } from "react";
import PageTitle from "../../Components/pageTitle";

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

import data from '../../applications.json';
import ApplicationItem from "./item";

import './style.scss';
import FormModal from "./FormModal";

const Applications = () => {


    // eslint-disable-next-line
    const [applications, setApplications] = useState(data.applications);
    const { isOpenModal } = useAppSelector((state: RootState) => state.applicationsStore);

    return(
        <>
        <PageTitle 
            title="Viva Braslav 2024"
            subtitle="Заявки на участие в фестивале"
        />
        <div className={isOpenModal ? 'applications open-form' : 'applications'}>
            <div className="applications_container">
                <div className="applications_wrapper">
                    {applications.map((item) => {
                        return(<ApplicationItem key={item.id} itemInfo={item} />);
                    })}
                </div>
            </div>
            <FormModal />
        </div>
        </>
    );
}

export default Applications;