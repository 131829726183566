import '../StepLoading/style.scss';

import icon from './img/item.svg';

const StepSuccess = () => {
    return(
        <div className="application-modal_final">
            <img src={icon} alt="success icon" className="application-modal_final_icon" />
            <span className="application-modal_final_title">Спасибо, заявка отправлена!</span>
            <span className="application-modal_final_text">На указанный адрес электронной почты будет направлено письмо о получении заявки.</span>
            <span className="application-modal_final_text">В случае одобрения заявки с Вами свяжется наш менеджер.</span>
        </div>
    ); 
}

export default StepSuccess;