import { useState } from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";

import StepOne from "./StepOne";

import ProgresBar from "../FormComponents/ProgresBar";
import NavigationButtons from "../FormComponents/NavigationButtons";
import StepLoading from "../FormComponents/StepLoading";
import StepSuccess from "../FormComponents/StepSuccess";
import StepWrong from "../FormComponents/StepWrong";

import '../style_form.scss';
import FormHeader from "../FormComponents/FormHeader";


interface Inputs {
    appliactionType: string,
    contactPerson: string,
    contactPhone: string,
    contactEmail: string,
    agreeRules: boolean
}

const defaultValues: Inputs = {
    appliactionType: 'alfabank',
    contactPerson: '',
    contactPhone: '',
    contactEmail: '',
    agreeRules: false
}; 

const AlfabankForm = () => {

    const applicationName="Заявка на бэкстейдж-экскурсию";

    const { control, register, handleSubmit, setValue, setError,  formState: { dirtyFields, errors } } = useForm<Inputs>({defaultValues});

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://vivabraslav.by/prod-api/applications.php',
            data : dataSend
        };

        const sendToServer = () => {
            dataSend.append('data', JSON.stringify(data));
            dataSend.append('g-recaptcha-response', "test");

            axios(config)
            .then(function () {
                setStep({ ...step, actual: 6 });
            })
            .catch(function () {
                setStep({ ...step, actual: 7 });
            });
        }

        if(step.actual === step.max || step.actual === 7 || step.actual === 6) {
            setStep({ ...step, actual: 5 });
            sendToServer();
        } else {
            setStep({ ...step, actual: step.actual + 1 });
        }

    }

    const [step, setStep] = useState({
        actual: 0,
        max: 0
    });

    const changeStep = () => {
        if(step.actual > 0) {
            setStep({
                ...step,
                actual: step.actual - 1
            });
        }
    }

    const getStep = () => {
        switch (step.actual) {
            case 0:
                return(
                    <StepOne
                        register={register} 
                        errors={errors}
                        control={control}
                        handleSet={setValue}
                        fields={dirtyFields}
                        setError={setError}
                    />
                );           
            case 5: 
                return(<StepLoading key={6}/>);
            case 6: 
                return(<StepSuccess key={7}/>);    
            case 7: 
                return(<StepWrong key={8}/>);     
        }
    }

    return(
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            <form className="application-modal_steps" onSubmit={handleSubmit(onSubmit)}>
                {getStep()}
                <div className="application-modal_navigation">
                    <NavigationButtons 
                        stepInfo={step} 
                        stepPrev={changeStep} 
                    />
                </div>
            </form>   
        </div>
    );
}

export default AlfabankForm;