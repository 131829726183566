import { Checkbox, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import TextMask, { TextMaskProps } from 'react-text-mask';

const StepOne = ({register, errors, control }) => {

    const TextMaskCustom = React.forwardRef (
        // eslint-disable-next-line
        (props: TextMaskProps, ref: React.ForwardedRef<HTMLInputElement>) => {
          return (
            <TextMask
              ref={(ref) => {return ref}}
              mask={props.mask}
              guide={false}
              placeholderChar={"_"}
              {...props}
            />
          );
        }
    );

    return(
        <div className="application-modal_step" key={0}>
            <TextField
                {...register('appliactionType')}
                name="appliactionType"
                inputProps={{ type: 'hidden' }}
                defaultValue="artists"
                style={{display: "none"}}
            />

            <FormControl className="application-modal_question">
                <FormControlLabel
                        control={
                        <Checkbox
                            size="small"
                            {...register('agreeRules', { required: true })}
                        />
                        }
                        label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                        sx={{
                            '& .MuiFormControlLabel-label': {
                            fontSize: "12px"
                            },
                        }}
                    />
                {errors.agreeRules && <FormHelperText error>Обязательное поле</FormHelperText>}
            </FormControl>
                


            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Фамилия, имя, отчество:</span>
                <TextField 
                    size="small" 
                    {...register('fullName', { required: true })}
                    error={!!errors.fullName}
                    helperText={errors.fullName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Пол:</span>
                <Controller
                    name="sex"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Мужской" control={<Radio size="small" />} label="Мужской" />
                            <FormControlLabel value="Женский" control={<Radio size="small" />} label="Женский" />
                        </RadioGroup>
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Дата рождения:</span>
               <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => (
                    
                    <TextField
                        {...field}
                        size="small" 
                        {...register('birthday')}
                        error={!!errors.birthday}
                        helperText={errors.birthday && "Обязательное поле"}
                        InputProps={{
                        // eslint-disable-next-line
                        inputComponent: TextMaskCustom,
                        inputProps: {
                            mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
                        },
                        }}
                        placeholder="__.__.____"
                    />
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Контактный телефон:</span>
               <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field }) => (
                    
                    <TextField
                        {...field}
                        size="small" 
                        {...register('contactPhone')}
                        error={!!errors.contactPhone}
                        helperText={errors.contactPhone && "Обязательное поле"}
                        InputProps={{
                        // eslint-disable-next-line
                        inputComponent: TextMaskCustom,
                        inputProps: {
                            mask: [ "+", "3", "7", "5", " ", "(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
                        },
                        }}
                        placeholder="+375 (12) 345-67-89"
                    />
                    )}
                />
            </FormControl>

            

            

        </div>
    );   
}

export default StepOne;