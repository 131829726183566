
import { Link } from "react-router-dom";
import PageTitle from "../../Components/pageTitle";
import './style.scss';

const Payment = () => {
    return(
        <>
            <PageTitle 
                title="Оплата"
                subtitle=""
            />
            <div className="payment">
                <div className="payment_container">
                    <div className="payment_wrapper">
                        <h1 className="payment_title">Способы оплаты</h1>
                        <span className="payment_info">Мы принимаем платежи по банковским картам: Visa, Visa Electron, MasterCard, Maestro, Белкарт.</span>
                        <div className="payment_block">
                            <h1 className="payment_title">Как совершить заказ</h1>
                            <ol className="payment_list">
                                <li className="payment_list_item">Перейдите на страницу <Link to="/tickets">“Билеты”</Link>, где размещена информация о всех категориях билетов.</li>
                                <li className="payment_list_item">Нажмите кнопку “Купить”;</li>
                                <li className="payment_list_item">В открывшемся модальном окне выберите необходимое количество нужных билетов;</li>
                                <li className="payment_list_item">В нижней части модального окна нажмите на кнопку “Продолжить”. Проверьте правильность Вашего заказа;</li>
                                <li className="payment_list_item">Нажмите кнопку “Продолжить” и перейдите на окно выбора способа оплаты и ввода адреса Вашей электронной почты. Заполнив все контактные данные, нажмите на кнопку “Продолжить”;</li>
                                <li className="payment_list_item">Введите данные Вашей банковской карты в открывшемся окне ввода данных (платежи по банковским картам осуществляются через систему электронных платежей bePaid. Платежная страница системы <a href="https://bepaid.by" rel="noreferrer" target="_blank"><b><div>be</div>Paid</b></a> отвечает всем требованиям безопасности передачи данных (PCI DSS Level 1). Все конфиденциальные данные хранятся в зашифрованном виде и максимально устойчивы к взлому. Доступ к авторизационным страницам осуществляется с использованием протокола, обеспечивающего безопасную передачу данных в Интернетe (SSL/TLS));</li>
                                <li className="payment_list_item">После успешной оплаты Вы сможете скачать билет, чек, которые будут высланы на указанный Вами адрес электронной почты.</li>
                            </ol>
                            <span className="payment_info">К любому билету добавляется “Работа сервиса” – оплата, которая взимается за обработку заказа.</span>    
                        </div>
                        <h1 className="payment_title">Мне не пришёл билет на почту</h1>
                        <span className="payment_info">С момента покупки до момента входящего письма с билетом на Вашу электронную почту, может занять некоторое время (продолжительностью до 30 минут). Проверьте папку “Спам” или “Нежелательные”. Если письмо с билетом всё же не пришло – свяжитесь с нами: support@ticketok.by или +375(29)277-10-59.</span>
                        
                    </div>
                </div>
            </div>
        </>
      );
}

export default Payment;