const Code = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
 {font-family:"Cambria Math";
 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
 {font-family:Calibri;
 panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
 {margin-top:0cm;
 margin-right:0cm;
 margin-bottom:8.0pt;
 margin-left:0cm;
 line-height:107%;
 font-size:11.0pt;
 font-family:"Calibri",sans-serif;}
.MsoChpDefault
 {font-size:11.0pt;
 font-family:"Calibri",sans-serif;}
.MsoPapDefault
 {margin-bottom:8.0pt;
 line-height:107%;}
@page WordSection1
 {size:595.3pt 841.9pt;
 margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
 {page:WordSection1;}
-->
</style>

</head>

<body lang=ru-PL link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>Каждый гость, приобретая билет
«Глэмпинг </span><span lang=EN-US style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>comfort</span><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'> 4 дня» или «Глэмпинг </span><span lang=EN-US style='font-size:
12.0pt;line-height:107%;font-family:"Times New Roman",serif;color:black'>luxury</span><span
lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'> </span><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>4 дня», соглашается с данными
правилами и обязуется неукоснительно их соблюдать.</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>При выраженных симптомах
заболевания (кашель, головная боль, небольшое повышение температуры и др.) –
воздержитесь от посещения мероприятия. Рекомендуем оставаться дома и обратиться
за помощью к медицинским сотрудникам.</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>Возрастные</span></b><span lang=RU style='font-size:12.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:black'> <b>ограничения</b></span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>С 9:00 до 23:00 – 0+<br>
До 12 лет вход на территорию глэмпинга разрешён только в сопровождении
родителей или официальных попечителей несовершеннолетнего лица.<br>
<br>
С 23:00 до 9:00 – 18+<br>
После 23:00 на территории глэмпинга, лицам до 18 лет находиться без
сопровождения родителей, опекунов или попечителей – ЗАПРЕЩЕНО.</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>Сотрудники Службы безопасности
вправе попросить предъявить оригинал документа, удостоверяющего личность и
возраст (паспорт, водительское удостоверение) для контроля возраста в случае
сомнений, о достижении гостем 18 лет.</span></p>

<p class=MsoNormal align=center style='text-align:center'><b><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>Билет, контрольно-пропускной браслет</span></b></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>Место продажи билетов –
официальный сайт международного спортивно-музыкального фестиваля «</span><span
lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>VIVA</span><span lang=EN-US style='font-size:12.0pt;line-height:
107%;font-family:"Times New Roman",serif;color:black'> </span><span lang=EN-US
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>BRASLAV</span><span lang=RU style='font-size:12.0pt;line-height:
107%;font-family:"Times New Roman",serif;color:black'>»: </span><span lang=RU
style='color:black'><a href="http://www.vivabraslav.by"><span lang=EN-US
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>www</span><span style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>.</span><span lang=EN-US
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>vivabraslav</span><span style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>.</span><span lang=EN-US
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>by</span></a></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><a
name="_Hlk136192848"><u><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>Билет «Глэмпинг </span></u></a><u><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>comfort</span></u><u><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
4 дня» (обменивается на контрольно-пропускной браслет соответствующей категории);</span></u></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>Деревянный домик размером 2,5х2,5 метра. Оборудован: четырьмя
спальными местами (в комплекте с матрасами), напольным ковриком, небольшим
столиком, электроэнергией, светильником, розетками и настенными крючками для
одежды. Домик закрывается на замок.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;'><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Билет
включает в себя:<a name="_Hlk136192593"></a></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ на территорию глэмпинга в период
с 8:00 27 июля до 16:00 30 июля 2023 г.;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
проживание в домике до ЧЕТЫРЁХ гостей фестиваля </span><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>не младше 18-ти лет (кроме случаев</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> с сопровождением родителей или по их поручению) и ТОЛЬКО
при наличии</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> билета «Стандарт 2 дня» или «</span><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>VIP</span><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black;'> 2 дня» на международный
спортивно-музыкальный</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> фестиваль «</span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;'>VIVA</span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'> </span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;'>BRASLAV</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>»;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><a name="_Hlk136192717"><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- неограниченное
количество входов/выходов;</span></a></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><a
name="_Hlk136192731"><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'>- предоставление парковочного места (расстояние
до территории глэмпинга 200 метров)</span></a></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> для ОДНОГО транспортного средства, в период с 8:00 27 июля
до 16:00 30 июля 2023 г.,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> (55.642026, 27.024078 - координаты для заезда на
парковку); </span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><a name="_Hlk136192884"><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- доступ
к фуд-корту (напитки и еда оплачиваются отдельно);</span></a></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ в зону для курения кальянов
(кальяны оплачиваются отдельно);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к душу (услуга оплачивается
отдельно);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к оборудованной зоне «Барбекю» с
уже установленным мангалом;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к санитарной зоне (биотуалеты,
рукомойники).</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><u><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Билет
«Глэмпинг </span></u><u><span lang=EN-US style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>luxury</span></u><u><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> 4
дня» (обменивается на контрольно-пропускной браслет соответствующей категории);</span></u></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>Домик, который представляет
собой деревянный каркас с тентом, установленный на деревянном полу размером 3х4
метра. Оборудован: двумя спальными местами, матрасами, комплектами для сна
(простынь, одеяло, пододеяльник, подушка, наволочка), небольшим холодильником с
мини-баром, двумя стульями, напольным ковриком, электроэнергией, розетками и
светильником. Домик закрывается на замок. Стандартное размещение спальных мест раздельное,
при необходимости разместить спальные места вместе просьба обращаться на почту:
</span><span lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:
"Times New Roman",serif;color:black'>GlampingBraslav</span><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>@</span><span lang=EN-US style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>gmail</span><span lang=RU
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'>.</span><span lang=EN-US style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>com</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>Билет включает в себя:</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ на территорию глэмпинга в период с
8:00 27 июля до 16:00 30 июля 2023 г.;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- проживание в домике ДВУХ гостей
фестиваля не младше 18-ти лет (кроме случаев с</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> сопровождением
родителей или по их поручению) <span style=''>и ТОЛЬКО при
наличии </span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> билета «Стандарт 2 дня» или «</span><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>VIP</span><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black;'> 2 дня» на международный
спортивно-музыкальный</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> фестиваль «</span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;'>VIVA</span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'> </span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;'>BRASLAV</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>»;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- неограниченное количество
входов/выходов;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>- предоставление парковочного места (расстояние до территории
глэмпинга 200 метров)</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> для ОДНОГО транспортного средства, в период с 8:00 27 июля
до 16:00 30 июля 2023 г., </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:7.1pt;text-indent:-14.2pt;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'> (55.642026, 27.024078 - координаты для заезда на парковку);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- возможность пригласить на территорию
глэмпинга до ЧЕТЫРЁХ гостей (ТОЛЬКО при наличии у гостя билета <span
style=''>«Стандарт 2 дня» или «</span></span><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
'>VIP</span><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black;'> 2 дня» на</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
</span><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'>международный спортивно-музыкальный фестиваль «</span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'>VIVA</span><span lang=EN-US style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:black;'> </span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'>BRASLAV</span><span lang=RU style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:black;'>»)</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
в <span style=''>период с 8:00 27 июля до 16:00 30</span></span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
</span><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;'>июля 2023 г.;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к фуд-корту (напитки и еда
оплачиваются отдельно);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ в зону для курения кальянов
(кальяны оплачиваются отдельно);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к душу (услуга оплачивается
отдельно);</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к оборудованной зоне «Барбекю» с
уже установленным мангалом;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>- доступ к санитарной зоне (биотуалеты,
рукомойники).</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>Для доступа в глэмпинг билет обязательно
должен быть обменен на</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>контрольно-пропускной браслет (далее –
браслет). Обмен билета на браслет</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>осуществляется в специально отведённом
месте (въезд на стоянку глэмпинга). Только</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>после получения браслета, а также при
наличии билета или браслета «Стандарт 2 дня» или</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>«</span><span lang=EN-US style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:black'>VIP</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
2 дня» на международный спортивно-музыкальный фестиваль «</span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>VIVA</span><span lang=EN-US style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'> </span><span lang=EN-US style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:black'>BRASLAV</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>»</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>(далее-фестиваль), можно пройти на территорию
глэмпинга. Полученный браслет даёт</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>право на неограниченное количество входов/выходов
на территорию глэмпинга через</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>специально организованную входную группу.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>Браслет на руки не выдаётся, надевается на
руку лично сотрудником глэмпинга и не</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>подлежит передаче третьим лицам!</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>Носить браслет необходимо всё время в
течение срока его действия. Сняв данный браслет,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-indent:-7.1pt;line-height:
normal;'><span lang=RU style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>гость теряет возможность находиться на
территории глэмпинга или повторно зайти на неё.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>За наличие,
сохранность и целостность браслета гость несёт персональную ответственность.
Повреждённые браслеты считаются недействительными. Сотрудники
контрольно-пропускного пункта имеют право отказать во входе в глэмпинг с
повреждёнными браслетами (браслет порван, склеен, сшит, у него сломана
застёжка, обрезаны кончики или браслет с другими явными визуальными
повреждениями). Факт повреждения данного браслета устанавливают сотрудники
глэмпинга. Повреждённые и потерянные браслеты не подлежат замене. Наличие и соответствие
браслетов будут проверяться сотрудниками глэмпинга во время входа/выхода в
него, а также на его территории в любое время в течении всего время его работы.
В случае утери или повреждения браслета, гостю необходимо обратиться к
сотруднику глэмпинга.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>ВАЖНО!
Покупатель билета «Глэмпинг </span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black'>comfort</span><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> 4
дня» или «Глэмпинг </span><span lang=EN-US style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:black'>luxury</span><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'> 4
дня» при совершении его оплаты подтверждает, что ознакомлен с Политикой
обработки и защиты персональных данных, размещенной на сайте
https://vivabraslav.by/, дает свое согласие на фото-, аудио-, видеозапись и на
общедоступность применения этих материалов в социальных сетях, на сайте, в
средствах массовой информации.</span><span lang=RU style='color:black'> </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>Правила поведения</span></b></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>На
всей территории глэмпинга действуют все законы Республики Беларусь. Для
обеспечения Вашей безопасности, на зонах глэмпинга ведётся видеонаблюдение.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><b><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Во
время нахождения на территории глэмпинга гости обязаны:</span></b><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
соблюдать и поддерживать общественный порядок; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- не
допускать действий, способных привести к возникновению экстремальных ситуаций и
создающих опасность для окружающих; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
бережно относиться к сооружениям и оборудованию объектов глэмпинга; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
вести себя уважительно по отношению к другим гостям, обслуживающему персоналу глэмпинга,
должностным лицам, ответственным за поддержание общественного порядка и
безопасности во время работы глэмпинга; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- с
пониманием относиться к необходимости прохождения процедуры досмотра при входе
в глэмпинг; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
неукоснительно выполнять все законные требования сотрудников правоохранительных
органов и иных лиц, ответственных за поддержание порядка и безопасности, администрации
и службы безопасности глэмпинга; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- не
оставлять без присмотра несовершеннолетних детей; гости с несовершеннолетними
детьми несут полную ответственность за их состояние, здоровье и
местонахождение; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
парковать транспортные средства в специально отведенных местах; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
соблюдать чистоту на территории глэмпинга, а также на прилегающей к ней;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>- при
получении информации об эвакуации - действовать согласно указаниям
ответственных за обеспечение правопорядка, соблюдать спокойствие и не создавать
паники. </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><b><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Гостям
глэмпинга запрещается: </span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
проход в глэмпинг в состоянии алкогольного опьянения и (или) в состоянии,
вызванным потреблением наркотических средств, психотропных веществ, их
аналогов, токсических или других одурманивающих веществ; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
допуск выкриков (в том числе с использованием ненормативной лексики) или иных
действий, оскорбляющих честь и достоинство других людей; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
разжигание костров, в том числе в мангалах, вне специально оборудованной зоны;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
пронос и употребление алкогольных напитков, за исключением пива и напитков с
объемной долей этилового спирта до 7% в пластиковой таре объемом не более 1
(одного) литра на 1 (одного) человека; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
пронос запрещенных к обороту предметов и веществ (в том числе наркотических и
психотропных), огнестрельного и холодного оружия, колющего, режущего, а также
иных предметов, которые могут быть использованы для нанесения телесных
повреждений, а также их имитаторов и муляжей, пиротехнических изделий, огне- и
взрывоопасных, ядовитых и раздражающих слизистые оболочки человеческого
организма веществ, лазеров; - проносить любые изделия из стекла, в том числе
посуду, кальяны и другие стеклянные предметы, которые могут причинить вред
окружающим;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
пронос профессиональных фото- и видеокамер, включая цифровые зеркальные
фотоаппараты без специального разрешения; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
пронос лекарственных препаратов, кроме жизнеобеспечивающих, противоаллергенных
и жидкостей для контактных линз. Если Вам необходимо иметь с собой лекарство,
будьте готовы предъявить медицинские документы, подтверждающие необходимость
использования нужных вам лекарств; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
совершение действий, оскорбляющих других людей, нарушающих общественный порядок
и угрожающих общественную безопасность;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
создание помех передвижения гостей глэмпинга и транспортных средств;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
забираться на ограждения, осветительные устройства, деревья, крыши, несущие
конструкции и другие сооружения, не предназначенные для размещения на них
людей; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
повреждение оборудования, элементов оформления сооружений и зелёных насаждений;
</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
несанкционированная торговля; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-
нанесение на любые поверхности и предметы, использование плакатов и иной
демонстрационной продукции из любых материалов, демонстрирующих условные
обозначения, символику, лозунги, направленные на разжигание расовой,
социальной, национальной, религиозной и иной ненависти и вражды.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>Безопасность</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>При
обнаружении бесхозных вещей и подозрительных предметов, не трогая их,
обратитесь к сотрудникам правоохранительных органов или к сотрудникам глэмпинга.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>В
случае если Вы стали свидетелем любого правонарушения или чрезвычайной
ситуации, необходимо как можно быстрее сообщить об этом любому ближайшему
сотруднику правоохранительных органов, службе безопасности или сотруднику глэмпинга.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Все
гости глэмпинга несут личную ответственность за сохранность своих вещей. Будьте
внимательны и не оставляйте ценные вещи без присмотра! Администрация глэмпинга
и служба безопасности не несут ответственности за пропажу и утрату личных вещей
во время работы глэмпинга.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>ВНИМАНИЕ!
В случае нахождения или утери личных вещей обратитесь к сотрудникам глэмпинга! </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Администрация
глэмпинга не несет ответственности за травмы и несчастные случаи, произошедшие
на территории глэмпинга и за её пределами в результате нарушения техники
безопасности и несоблюдения настоящих «Правил посещения глэмпинга». </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Гости глэмпинга,
не соблюдающие правила поведения, могут быть привлечены к ответственности в
соответствии с действующим законодательством Республики Беларусь и могут быть
удалены с территории глэмпинга без компенсации стоимости билета. </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'><b>Домашние животные</b></span></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Проход
с домашними животными на территорию глэмпинга разрешён при наличии намордника
(у собак выше 25 см в холке) и короткого поводка (до 1 м) </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Запрещено
оставлять животное без присмотра хозяина. В случае, если животное опорожнилось
на территории глэмпинга, то хозяин обязан самостоятельно убрать за животным. </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>Фото и видео-съемка</span></b></p>

<p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:-7.1pt;text-align:center;line-height:normal;
'><b><span lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Производить
съемку на не профессиональное фото- и видеооборудование (телефоны, go-pro)
имеет право каждый гость глэмпинга. </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>Использование
квадрокоптера возможно при наличии аккредитации и наличия разрешительной
документации в соответствии с законодательством Республики Беларусь. Съемка
профессиональным фото- видеооборудованием, а также цифровыми зеркальными
фотоаппаратами возможна при наличии аккредитации на фестивале. Аккредитацию
получают фотографы, прошедшие конкурсный отбор. Аккредитованный фотограф
получает специальный бейдж. </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:-7.1pt;line-height:normal;'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>ВАЖНО!
Покупатель при совершении оплаты билета дает свое согласие на фото-, аудио-,
видеозапись на территории глэмпинга и на общедоступность применения этих
материалов в социальных сетях, на сайте, в средствах массовой информации.</span><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>
<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;'><span
lang=RU style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:black'>&nbsp;</span></p>

</div>

</body>

</html>
`;

export default Code;
