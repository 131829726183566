import './style.scss';
import infoIcon from '../../../img/tickets/info-icon.svg';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { openModalBuy, openModalMore } from '../../../redux/slices/ticketsModalSlice';

type TicketProps = {
    // eslint-disable-next-line
    info: any;
    categoryId: number;
}

const Ticket = ({ info, categoryId }: TicketProps) => {

    const { id, title, description, price, status, next_prices, old_prices, description_list} = info;

    const [showAllPrices, setShowAllPrices] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const onClick = (e) =>  {
            if(e.target.className !== 'places-ticket_price_all' && e.target.className !== 'places-ticket_price_all_icon') {
                setShowAllPrices(false); 
            } 
        };
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
      }, []);

    const getBtn = (status: string) => {
        switch (status) {
            case 'soldout':
            case 'sold':
                return <div className="places-ticket_btns_sold">Распродано</div>
            case 'soon':
                return <div className="places-ticket_btns_sold">Скоро</div>    
        
            default:
                return <div className="places-ticket_btns_buy shine" onClick={() => dispatch(openModalBuy({category_id: categoryId, ticket_id: id}))}>Купить</div>;
        }
    }

    const getBtnMore = () => {
        if(description_list && description_list.length > 0) {
            return <div className="places-ticket_btns_more" onClick={() => dispatch(openModalMore({category_id: categoryId, ticket_id: id}))}>Подробнее</div>;
        } else {  
            return <></>;
        }
    }

    const getPrice = (status: string) => {
        switch (status) {
  
            case 'soldout': 
                return <div className="places-ticket_price_text">SOLD OUT</div>
                
            case 'soonWithoutPrice':
                return (null)
        
            default:
                return(
                    <div className="places-ticket_price" style={!price ? {display: 'none'} : {}}>
                        <div className="places-ticket_price_current">
                            <div className="places-ticket_price_current_close" style={status==='sold' ? {display: 'block'} : {}}></div>
                            <span className="places-ticket_price_whole">{price.split('.')[0]}</span>
                            <span className="places-ticket_price_fractional">{price.split('.')[1]}</span>
                            <span className="places-ticket_price_currency">Br</span>
                        </div>
                        <div className="places-ticket_price_all">
                            <img src={infoIcon} alt="Viva Braslav Цены" className="places-ticket_price_all_icon" onClick={() => setShowAllPrices(!showAllPrices)} />
                            <div className="places-ticket_prices">
                                <div className="places-ticket_prices_info_block" style={showAllPrices ? {opacity: "1", pointerEvents: "all"} : {pointerEvents: "none"}}>
                                    {old_prices.map((item: string, index: number) => {
                                        return(<span className="places-ticket_prices_info_item price_old" key={index}>{item} Br</span>);
                                    })}
                                    <span className="places-ticket_prices_info_item price_now" style={status === 'sold' ? {textDecoration: 'line-through'} : {}}>{price} Br</span>
                                    {next_prices.map((item: string, index: number) => {
                                        return(<span className="places-ticket_prices_info_item price_next" key={index}>{item} Br</span>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
      }

    return (
        <div className="places-ticket" key={id}>
            <div className="places-ticket_wrapper">
                <div className="places-ticket_information">
                    <div className="places-ticket_name">
                        <h4 className="places-ticket_name_title">Билет "{title}"</h4>
                        <span className="places-ticket_name_text">{description}</span>
                    </div>
                    {getPrice(status)}
                </div>
                <div className="places-ticket_btns">
                    {getBtnMore()}
                    {getBtn(status)}
                </div>
            </div>
        </div>
    );
}

export default Ticket;