import { useAppDispatch } from '../../../../app/hooks';
import { closeModal } from '../../../../redux/slices/applicationsSlice';
import './style.scss';

const FormHeader = ({applicationName}) => {
    const dispatch = useAppDispatch();
    return(
        <>
            <div className="application-modal_header">
                <div className="application-modal_wave">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" fill="#007C00"/>
                    </svg>
                </div>
                <div className="application-modal_close" onClick={()=>dispatch(closeModal())}></div>
            </div>
            <span className="application-modal_title">{applicationName}</span>
        </>
    );
}

export default FormHeader;