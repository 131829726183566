import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const StepTwo = ({register, errors, watch, control}) => {
    const power220 = watch('voltage220');
    const power380 = watch('voltage380');
    return(
        <div className="application-modal_step" key={1}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Необходимо ли напряжение <b>220В</b>:</span>
                <Controller
                    name="voltage220"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {power220 === "Да" && (
                    <TextField 
                        size="small" 
                        autoComplete="off" 
                        style={{marginTop: "10px"}} 
                        label="Мощность в кВт" 
                        {...register('powerFor220', { 
                            required: "Обязательное поле", 
                            min: { value: 1, message: "Минимальное значение: 1" }, 
                            max: { value: 50, message: "Максимальное значение: 50" } 
                            })}
                        error={!!errors.powerFor220}
                        helperText={errors.powerFor220 ? errors.powerFor220.message : ""}
                    />
                )}
                {errors.voltage220 && <span className="application-modal_question_error">Обязательное поле</span>}
            </FormControl> 

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Необходимо ли напряжение <b>380В</b>:</span>
                <Controller
                    name="voltage380"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {power380 === "Да" && (
                    <TextField 
                        size="small" 
                        autoComplete="off" 
                        style={{marginTop: "10px"}} 
                        label="Мощность в кВт" 
                        {...register('powerFor380', { 
                            required: "Обязательное поле", 
                            min: { value: 1, message: "Минимальное значение: 1" }, 
                            max: { value: 50, message: "Максимальное значение: 50" } 
                            })}
                        error={!!errors.powerFor380}
                        helperText={errors.powerFor380 ? errors.powerFor380.message : ""}
                    />
                )}
                {errors.voltage380 && <span className="application-modal_question_error">Обязательное поле</span>}
            </FormControl> 

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Наличие банковского терминала:</span>
                <Controller
                    name="bankTerminal"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {errors.bankTerminal && <span className="application-modal_question_error">Обязательное поле</span>}
            </FormControl>
        </div>
    );
}

export default StepTwo;