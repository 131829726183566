import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { api } from '../redux/api';
import applicationsReducer from '../redux/slices/applicationsSlice';
import ticketsModalSlice from '../redux/slices/ticketsModalSlice';
import programSlice from '../redux/slices/programSlice';

export const store = configureStore({
  reducer: {
    applicationsStore: applicationsReducer,
    ticketsModalStore: ticketsModalSlice,
    programStore: programSlice,

    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
