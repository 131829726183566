import '../StepLoading/style.scss';

const StepWrong = () => {
    return(
        <div className="application-modal_final">
            <span className="application-modal_final_title">Что-то пошло не так!</span>
            <span className="application-modal_final_text">Заявка не была отправлена. Попробуйте ещё раз.</span>
            <span className="application-modal_final_text">Если проблема повторяется - свяжитесь с нами.</span>
            <input className="btn-next" type="submit" value="Повторить" />
        </div>
    );   
}

export default StepWrong;