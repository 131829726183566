import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageTitle from "../../Components/pageTitle";
import "./style.scss";

const FestivalRules = () => {
    const pdfUrl = "https://vivabraslav.by/docs/regulamin2024.pdf"; // Replace with your PDF URL
    const uniquePdfUrl = `${pdfUrl}?t=${new Date().getTime()}`;
    const { pathname } = useLocation();

    const [showIframe, setShowIframe] = useState(false);

    useEffect(() => {
        // Сброс состояния при каждом изменении маршрута
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        setShowIframe(false);

        const iframe = document.querySelector('iframe');
        if (iframe) {
            iframe.src = `https://docs.google.com/viewer?url=${uniquePdfUrl}&embedded=true`; // Принудительное обновление src, чтобы перезагрузить iframe
        }

        // Включение отображения iframe после небольшой задержки
        const timer = setTimeout(() => setShowIframe(true), 1000); // Задержка в 100 мс

        return () => clearTimeout(timer);
    }, [pathname]);

    return (
        <>
            <PageTitle
                title="Правила посещения Viva&nbsp;Braslav&nbsp;2024"
                subtitle=""
            />
            <div className="contract">
                <div className="contract_container">
                    <div className="contract_wrapper">
                        <h1 className="contract_title">Информация</h1>
                        {showIframe && (
                            <iframe
                                key={new Date().getTime()} // Уникальный ключ
                                style={{ width: "90%", height: "900px" }}
                                src={`https://docs.google.com/document/d/e/2PACX-1vRby_nJqTTILJ7jsuzilJp6n2d7TegljuKBvBo1mHIw7j5h-qv0rodHkT4PcM9zTu1Sk-C9IJOjFgB2/pub?embedded=true`}
                                frameBorder="0"
                            ></iframe>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FestivalRules;
