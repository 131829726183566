import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import tuborg from '../../../../img/applications/tuborg.png';

import TextMask, { TextMaskProps, MaskedInput } from 'react-text-mask';

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";

const StepTwo = ({register, errors, control}) => {
    
    const TextMaskCustom = React.forwardRef (
        // eslint-disable-next-line
        (props: TextMaskProps, ref: React.ForwardedRef<HTMLInputElement>) => {
          return (
            <TextMask
              ref={(ref) => {return ref}}
              mask={props.mask}
              guide={false}
              placeholderChar={"_"}
              {...props}
            />
          );
        }
    );

    
    return(
        <div className="application-modal_step" key={1}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Контактное лицо:</span>
                <TextField 
                    size="small" 
                    {...register('contactPerson', { required: true })}
                    error={!!errors.contactPerson}
                    helperText={errors.contactPerson && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl
                className="application-modal_question"
                fullWidth
                size="small">
                <span className="application-modal_question_title">
                    Контактный телефон:
                </span>
                <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field }) => (
                        <ReactPhoneInput
                            {...register("contactPhone", { required: true })}
                            {...field}
                            value={field.value} // Set the default country value
                            country="by"
                            onlyCountries={["by", "ru", "lt", "lv", "pl", "ua"]}
                            localization={ru}
                            inputProps={{
                                name: "contactPhone",
                                placeholder: "Начните писать тут...",
                                required: true,
                                autoformat: true,
                            }}
                            onChange={(value) => field.onChange(value)} // Update the field value on change
                        />
                    )}
                />
                {errors.contactPhone && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Email адрес:</span>
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (

                    <TextField
                        {...field}
                        size="small" 
                        type="email" 
                        {...register('contactEmail', { required: true })}
                        error={!!errors.contactEmail}
                        helperText={errors.contactEmail && "Обязательное поле"}
                        InputProps={{
                        // eslint-disable-next-line
                            inputComponent: MaskedInput as any,
                            inputProps: {
                                mask: [/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i],
                            },
                            style: {
                                backgroundColor: 'white',
                            },
                        }}
                        
                        placeholder="partner@mail.by"
                    />
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Дополнительная информация:</span>
                <TextField 
                    size="small" 
                    {...register('contactComment')}
                    error={!!errors.contactComment}
                    helperText={errors.contactComment && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <FormControlLabel
                        control={
                        <Checkbox
                            size="small"
                            {...register('agreeRules', { required: true })}
                        />
                        }
                        label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                        sx={{
                            '& .MuiFormControlLabel-label': {
                            fontSize: "12px"
                            },
                        }}
                    />
                {errors.agreeRules && <FormHelperText error>Обязательное поле</FormHelperText>}
            </FormControl>

            <FormControl className="application-modal_question">
                <img src={tuborg} alt="" style={{width: "100%", zIndex: 1}}/>
            </FormControl>
        </div>
    );   
}

export default StepTwo;