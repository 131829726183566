import {
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import { Controller } from "react-hook-form";

const freeTimeSelect = [
    "Есть всегда",
    "По выходным",
    "Свободное от работы/учёбы",
    "Только во время фестиваля",
];

const positionsSelect = [
    "Готов на всё",
    "Инфоцентр",
    "SMM",
    "Помощь в партнерских зонах",
    "Работа с мерчем фестиваля",
    "Организация спортивной площадки",
];

const StepThree = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={3}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Опыт волонтёрства/работы на фестивалях:
                </span>
                <Controller
                    name="expWork"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="Да"
                                control={<Radio />}
                                label="Да"
                            />
                            <FormControlLabel
                                value="Нет"
                                control={<Radio />}
                                label="Нет"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.expWork && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Наличие водительского удостоверения:
                </span>
                <Controller
                    name="hasLic"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="Да"
                                control={<Radio />}
                                label="Да"
                            />
                            <FormControlLabel
                                value="Нет"
                                control={<Radio />}
                                label="Нет"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.hasLic && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Наличие авто:
                </span>
                <Controller
                    name="hasAuto"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="Да"
                                control={<Radio />}
                                label="Да"
                            />
                            <FormControlLabel
                                value="Нет"
                                control={<Radio />}
                                label="Нет"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.hasAuto && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl size="small" className="application-modal_question">
                <span className="application-modal_question_title">
                    Свободное время:
                </span>
                <Controller
                    name="freeTime"
                    control={control}
                    {...register("freeTime", { required: true })}
                    render={({ field }) => (
                        <Select {...field} error={!!errors.freeTime}>
                            {freeTimeSelect.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                {errors.freeTime && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl size="small" className="application-modal_question">
                <span className="application-modal_question_title">
                    Что тебе было бы интересно:
                </span>
                <Controller
                    name="workType"
                    control={control}
                    {...register("workType", { required: true })}
                    render={({ field }) => (
                        <Select {...field} error={!!errors.workType}>
                            {positionsSelect.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                {errors.workType && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>
        </div>
    );
};

export default StepThree;
