import '../../../applications/FormComponents/StepLoading/style.scss';

import icon from './img/item.svg';

const StepSuccess = () => {
    return(
        <div className="application-modal_final">
            <img src={icon} alt="success icon" className="application-modal_final_icon" />
            <span className="application-modal_final_title">Спасибо, регистрация завершена!</span>
            <span className="application-modal_final_text">На указанный адрес электронной почты будет отправлена информация по Вашему аккаунту.</span>
        </div>
    ); 
}

export default StepSuccess;