import './style.scss';

const Forbidden = () => {
    return(
        <div className="forbidden">
            <div className="forbidden_container">
                <div className="forbidden_wrapper">
                    <h1 className="forbidden_title">Извините, ведутся технические работы</h1>
                    <h2 className="forbidden_text">Попробуйте зайти позже</h2>
                </div>
            </div>
        </div>
    );
    
}

export default Forbidden;