import { Box, CircularProgress } from "@mui/material";
import './style.scss';

const StepLoading = () => {
    return(
        <div className="application-modal_final">
            <span className="application-modal_final_text">Отправляем Вашу заявку...</span>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color="inherit" size={35}/>
            </Box>
        </div>
    );
}

export default StepLoading;