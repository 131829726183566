import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ru from 'react-phone-input-2/lang/ru.json';


import TextMask, { TextMaskProps } from "react-text-mask";



const StepOne = ({
    register,
    errors,
    control,
    handleSet,
    fields,
    setError,
}) => {
    const TextMaskCustom = React.forwardRef(
        // eslint-disable-next-line
        (props: TextMaskProps, ref: React.ForwardedRef<HTMLInputElement>) => {
            return (
                <TextMask
                    ref={(ref) => {
                        return ref;
                    }}
                    mask={props.mask}
                    guide={false}
                    placeholderChar={"_"}
                    {...props}
                />
            );
        }
    );



    return (
        <div className="application-modal_step" key={0}>
            <TextField
                {...register("appliactionType")}
                name="appliactionType"
                inputProps={{ type: "hidden" }}
                defaultValue="smi"
                style={{ display: "none" }}
            />

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Полное наименование организации:
                </span>
                <TextField
                    size="small"
                    {...register("fullName", { required: true })}
                    error={!!errors.fullName}
                    helperText={errors.fullName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Где планируется использован материал:
                </span>
                <TextField
                    size="small"
                    {...register("whereWillUse", { required: true })}
                    error={!!errors.whereWillUse}
                    helperText={errors.whereWillUse && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Фамилия Имя Отчество:
                </span>
                <TextField
                    size="small"
                    {...register("contactPerson", { required: true })}
                    error={!!errors.contactPerson}
                    helperText={errors.contactPerson && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question" fullWidth size="small">
            <span className="application-modal_question_title">Контактный телефон:</span>
                <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field }) => (
                    <ReactPhoneInput
                        {...register("contactPhone", { required: true })}
                        {...field}
                        value={field.value} // Set the default country value
                        country='by'
                        onlyCountries={['by', 'ru', 'lt', 'lv', 'pl', 'ua']}
                        localization={ru}
                        inputProps={{
                            name: 'contactPhone',
                            placeholder: 'Начните писать тут...',
                            required: true,
                            autoformat: true,
                        }}
                        onChange={(value) => field.onChange(value)} // Update the field value on change
                    />
                    )}
                />
            {errors.contactPhone && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            


        </div>
    );
};

export default StepOne;
