import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormControl, TextField, Divider, Typography, Box, Stack, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import "dayjs/locale/ru";

const StepTwo = ({ register, errors, control, handleSet, fields, setError }) => {
    const { getValues } = useFormContext();
    const applicationType = getValues("type");

    dayjs.locale("ru");
    const maxDate = dayjs("2006-07-27");

    const validateDate = (value) => {
        const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/;
        if (!regex.test(value)) {
            return "Введите корректную дату в формате ДД.ММ.ГГГГ";
        }
        const [day, month, year] = value.split('.').map(Number);
        const enteredDate = dayjs(`${year}-${month}-${day}`);
        if (!enteredDate.isValid()) {
            return "Введите корректную дату";
        }
        if (enteredDate.isAfter(maxDate)) {
            return `Дата должна быть не позже ${maxDate.format('DD.MM.YYYY')}`;
        }
        return true;
    };

    return (
        <div className="application-modal_step" key={0}>
            <Box sx={{ mb: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography gutterBottom variant="h6" component="div">
                        {applicationType !== "sport-1" ? "Данные участника" : "Данные родителя"}
                    </Typography>
                </Stack>
                <Typography variant="body2">
                    {applicationType !== "sport-1" ? "Для данного забега установлено возрастное ограничение 18+" : "В семейном забеге могут принять один родитель и один ребёнок"}
                    
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Фамилия Имя Отчество:
                </span>
                <TextField
                    size="small"
                    {...register("contactPerson", {
                        required: true,
                        validate: (value) => {
                            const words = value.trim().split(/\s+/);
                            return (
                                words.length === 3 ||
                                "Укажите, пожалуйста, ФИО полностью"
                            );
                        },
                    })}
                    error={!!errors.contactPerson}
                    helperText={errors.contactPerson?.message}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Пол:
                </span>
                <Controller
                    name="сontactSex"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="М"
                                control={<Radio />}
                                label="Мужской"
                            />
                            <FormControlLabel
                                value="Ж"
                                control={<Radio />}
                                label="Женский"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.сontactSex && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дата рождения:
                </span>
                <Controller
                    name="contactBirthday"
                    control={control}
                    rules={{ 
                        required: "Обязательное поле",
                        validate: validateDate
                    }}
                    render={({ field }) => (
                        <InputMask
                            {...field}
                            mask="99.99.9999"
                            maskChar={null}
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    size="small"
                                    placeholder="ДД.ММ.ГГГГ"
                                    error={!!errors.contactBirthday}
                                    helperText={errors.contactBirthday?.message}
                                />
                            )}
                        </InputMask>
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Страна проживания:
                </span>
                <TextField
                    size="small"
                    {...register("countryLive", { required: true })}
                    error={!!errors.countryLive}
                    helperText={errors.countryLive && "Обязательное поле"}
                />
            </FormControl>
        </div>
    );
};

export default StepTwo;
