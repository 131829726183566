import { FormControl, TextField } from "@mui/material";

const StepOne = ({register, errors }) => {

    return(
        <div className="application-modal_step" key={0}>
            <TextField
                {...register('appliactionType')}
                name="appliactionType"
                inputProps={{ type: 'hidden' }}
                defaultValue="artists"
                style={{display: "none"}}
                InputProps={{
                    style: {
                        backgroundColor: 'white',
                    },
                }}
            />

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Сценический псведоним артиста / название группы:</span>
                <TextField 
                    size="small" 
                    {...register('artistName', { required: true })}
                    error={!!errors.artistName}
                    helperText={errors.artistName && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Жанр музыки:</span>
                <TextField 
                    size="small" 
                    {...register('genre', { required: true })}
                    error={!!errors.genre}
                    helperText={errors.genre && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Опыт выступлений (указать мероприятия):</span>
                <TextField 
                    size="small" 
                    {...register('experience', { required: true })}
                    error={!!errors.experience}
                    helperText={errors.experience && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Ссылка на live-выступления:</span>
                <TextField 
                    size="small" 
                    {...register('artistLink', { required: true })}
                    error={!!errors.artistLink}
                    helperText={errors.artistLink && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Ссылка на сайт/социальные сети:</span>
                <TextField 
                    size="small" 
                    {...register('socialLink', { required: true })}
                    error={!!errors.socialLink}
                    helperText={errors.socialLink && "Обязательное поле"}
                    InputProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </FormControl>

        </div>
    );   
}

export default StepOne;