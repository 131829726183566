import { Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import { MaskedInput } from "react-text-mask";


const StepTwo = ({ register, errors, control }) => {

        
    const options = [
        { label: "Газета", value: "Газета" },
        { label: "Новостной портал", value: "Новостной портал" },
        { label: "Телеканал", value: "Телеканал" },
        // { label: "Блогер", value: "Блогер" },
    ];

    return (
        <div className="application-modal_step" key={1}>

<FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Email адрес:
                </span>
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            type="email"
                            {...register("contactEmail", { required: true })}
                            error={!!errors.contactEmail}
                            helperText={
                                errors.contactEmail && "Обязательное поле"
                            }
                            InputProps={{
                                // eslint-disable-next-line
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                    mask: [
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                    ],
                                },
                            }}
                            placeholder="your@mail.by"
                        />
                    )}
                />
            </FormControl>

            <FormControl size="small" className="application-modal_question">
                <span className="application-modal_question_title">
                    В качестве кого планируете присутствовать на фестивале?
                </span>
                <Controller
                    name="typeSmi"
                    control={control}
                    {...register("typeSmi", { required: true })}
                    render={({ field }) => (
                        <Select {...field} error={!!errors.typeSmi}>
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                {errors.typeSmi && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>
            
            

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка на портал или страницу:
                </span>
                <TextField
                    size="small"
                    {...register("orgLink", { required: true })}
                    error={!!errors.orgLink}
                    helperText={errors.orgLink && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Статистика ресурса за последний месяц (прикрепить ссылку на Диск):
                </span>
                <TextField
                    size="small"
                    {...register("statLink", { required: true })}
                    error={!!errors.statLink}
                    helperText={errors.statLink && "Обязательное поле"}
                />
            </FormControl>

            

            

          
        </div>
    );
};

export default StepTwo;
