import { FormControl, TextField, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Controller } from "react-hook-form";

const StepThree = ({register, errors, watch, control}) => {

    const additionalPlaceInput = watch('additionalPlace') || [];
    
    const options = [
        { label: 'Шатёр', value: 'Шатёр' },
        { label: 'Торговый прицеп', value: 'Торговый прицеп' },
        { label: 'Фудтрак', value: 'Фудтрак' },
        { label: 'Смартбокс', value: 'Смартбокс' },
    ];
    
    return(
        <div className="application-modal_step" key={2}>
            
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Размеры объекта:</span>
                <TextField 
                    size="small" 
                    autoComplete="off"
                    {...register('companySize', { required: true })}
                    error={!!errors.companySize}
                    helperText={errors.companySize && "Обязательное поле"}
                    label="ДхШ в метрах с учетом дышла прицепа"
                    style={{marginTop: "10px"}}
                />
                
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Требуется ли зона рядом с торговым объектом для размещения дополнительного оборудования?:</span>
                <Controller
                    name="additionalPlace"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {additionalPlaceInput === "Да" && (
                    <TextField 
                        size="small" 
                        autoComplete="off" 
                        style={{marginTop: "10px"}} 
                        label="Необходимые размеры (ДхШ в метрах)" 
                        {...register('additionalPlaceSize', { required: true })}
                        error={!!errors.additionalPlaceSize}
                        helperText={errors.additionalPlaceSize && "Обязательное поле"}
                    />
                )}
                {errors.additionalPlace && <span className="application-modal_question_error">Обязательное поле</span>}
            </FormControl> 

            <FormControl 
                size="small" 
                className="application-modal_question"
            >
                <span className="application-modal_question_title">Формат объекта:</span>
                <Controller
                    name="selectInput"
                    control={control}
                    {...register('selectInput', { required: true })}
                    render={({ field }) => (
                    <Select {...field}  error={!!errors.selectInput}>
                        {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                    )}
                />
                {errors.selectInput && <span className="application-modal_question_error">Обязательное поле</span>}
            </FormControl>
                
                            
        </div>
    );
}

export default StepThree;