import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

import './style.scss';

const ApplicationItem = ({ itemInfo }) => {

    const dispatch = useAppDispatch();

    const { icon, title, description, state, id } = itemInfo;
    const getBtn = (state: string) => {
        switch (state) {
            case 'soon':
                return(<div className="applications_item_info_btn-disable">Скоро</div>);
            case 'close':
                return(<div className="applications_item_info_btn-disable">Приём закрыт</div>);    
            default:
                return(<div className="applications_item_info_btn-active" onClick={() => dispatch(openModal(id))}>Подать заявку</div>);    
        }
    }
    return (
        <div className="applications_item" key={id}>
            <div className="applications_item_icon">
                <img src={"https://vivabraslav.by/"+icon} alt="App" className="applications_item_icon_img" />
            </div>
            <div className="applications_item_info">
                <span className="applications_item_info_title">{title}</span>
                <span className="applications_item_info_description">{description}</span>
                {getBtn(state)}
            </div>
        </div>
    );
}

export default ApplicationItem;