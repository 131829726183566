import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";

const StepThree = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={1}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Условия сотрудничества:
                </span>
                <TextField
                    size="small"
                    {...register("conditions", { required: true })}
                    error={!!errors.conditions}
                    helperText={errors.conditions && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дополнительная информация о себе, комментарий:
                </span>
                <TextField
                    size="small"
                    {...register("contactComment")}
                    error={!!errors.contactComment}
                    helperText={errors.contactComment && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <FormControlLabel
                        control={
                        <Checkbox
                            size="small"
                            {...register('agreeRules', { required: true })}
                        />
                        }
                        label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                        sx={{
                            '& .MuiFormControlLabel-label': {
                            fontSize: "12px"
                            },
                        }}
                    />
                {errors.agreeRules && <FormHelperText error>Необходимо Ваше согласие</FormHelperText>}
            </FormControl>
                

        </div>
    );
};

export default StepThree;
