import {
    FormControl,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

const StepFour = ({
    register,
    errors,
    selectedFiles,
    handleFileSelect,
    setValue,
    watch
}) => {

    const selfEmployed = watch('selfEmployed');

    return (
        <div className="application-modal_step" key={3}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Информация о компаниии:
                </span>
                <FormControlLabel
                    style={{ marginBottom: "10px" }}
                    control={
                        <Checkbox
                            size="small"
                            checked={selfEmployed}
                            onChange={(e) => {
                                setValue("selfEmployed", !selfEmployed, {
                                    shouldValidate: true,
                                });
                                if (e.target.checked) {
                                    setValue("unp", "Самозанятый", {
                                        shouldValidate: true,
                                    });
                                    setValue("curacc", "Самозанятый", {
                                        shouldValidate: true,
                                    });
                                    setValue("bic", "Самозанятый", {
                                        shouldValidate: true,
                                    });
                                } else {
                                    setValue("unp", "", {
                                        shouldValidate: true,
                                    });
                                    setValue("curacc", "", {
                                        shouldValidate: true,
                                    });
                                    setValue("bic", "", {
                                        shouldValidate: true,
                                    });
                                }
                            }}
                        />
                    }
                    label="Я самозанятый (самозанятая)"
                />
                {!selfEmployed && (
                    <>
                        <FormControl className="application-modal_question">
                            <TextField
                                size="small"
                                autoComplete="off"
                                placeholder="Юридический адрес"
                                {...register("companyaddress", {
                                    required: !selfEmployed,
                                })}
                                error={!!errors.bankRequisites}
                                helperText={
                                    errors.bankRequisites && "Обязательное поле"
                                }
                            />
                        </FormControl>
                        <FormControl
                            className="application-modal_question"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "20px",
                                flexDirection: "row",
                            }}>
                            <TextField
                                style={{ flex: 1 }}
                                size="small"
                                autoComplete="off"
                                placeholder="BIC банка"
                                {...register("bic", { required: !selfEmployed })}
                                error={!!errors.bankRequisites}
                                helperText={
                                    errors.bankRequisites && "Обязательное поле"
                                }
                            />
                            <TextField
                                style={{ flex: 1 }}
                                size="small"
                                autoComplete="off"
                                placeholder="УНП"
                                {...register("unp", { required: !selfEmployed })}
                                error={!!errors.unp}
                                helperText={errors.unp && "Обязательное поле"}
                            />
                        </FormControl>
                        <FormControl className="application-modal_question">
                            <TextField
                                size="small"
                                autoComplete="off"
                                placeholder="Расчётный счёт"
                                {...register("curacc", { required: !selfEmployed })}
                                error={!!errors.bankRequisites}
                                helperText={
                                    errors.bankRequisites && "Обязательное поле"
                                }
                            />
                        </FormControl>
                    </>
                )}
            </FormControl>

            <div className="application-modal_question">
                <span className="application-modal_question_title">
                    Фотографии объекта:
                </span>
                <div className="input__wrapper">
                    <input
                        name="file"
                        type="file"
                        id="input__file"
                        className="input input__file"
                        accept="image/png, image/jpg, image/jpeg"
                        multiple
                        onChange={handleFileSelect}
                    />
                    <label htmlFor="input__file" className="input__file-button">
                        <span className="input__file-button-text">
                            {selectedFiles.length > 0
                                ? "Прикреплено файлов: " + selectedFiles?.length
                                : "Нажмите сюда для загрузки файла(ов)"}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default StepFour;
