import PageTitle from '../../Components/pageTitle';

import iFrame from './rules';
import '../contract/style.scss';

const GlampingRules = () => {
    return (
        <>
        <PageTitle 
            title="Правила посещения глэмпинга"
            subtitle=""
        />
        <div className='contract'>   
            <div className="contract_container">
                <div className="contract_wrapper">
                    <h1 className="contract_title">Информация</h1>
                    <div dangerouslySetInnerHTML={{__html: iFrame}} />
                </div>
            </div>                   
        </div>
        </>             
    );
}

export default GlampingRules;