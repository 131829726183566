import { TextField, FormControl } from "@mui/material";

const StepOne = ({register, errors}) => {
    return (
        <div className="application-modal_step" key={0}>
            <TextField
                {...register('appliactionType')}
                name="appliactionType"
                inputProps={{ type: 'hidden' }}
                defaultValue="entertainment"
                style={{display: "none"}}
            />

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Полное наименование компании с указанием организационно-правовой формы:</span>
                <TextField 
                    size="small" 
                    autoComplete="off"
                    {...register('fullNameCompany', { required: true })}
                    error={errors.fullNameCompany ? true : false}
                    helperText={errors.fullNameCompany ? "Обязательное поле" : ''}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Торговое наименование/бренд:</span>
                <TextField 
                    size="small" 
                    autoComplete="off"
                    {...register('torgNameCompany', { required: true })}
                    error={!!errors.torgNameCompany}
                    helperText={errors.torgNameCompany && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Количество персонала:</span>
                <TextField 
                    size="small" 
                    autoComplete="off"
                    type="number" 
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                    {...register('personalCount', { required: true })}
                    error={!!errors.personalCount}
                    helperText={errors.personalCount && "Обязательное поле"}
                />
            </FormControl>
        </div>
    );
}

export default StepOne;