import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import TextMask, { TextMaskProps, MaskedInput } from "react-text-mask";

const StepOne = ({ register, errors, control }) => {
    const TextMaskCustom = React.forwardRef(
        // eslint-disable-next-line
        (props: TextMaskProps, ref: React.ForwardedRef<HTMLInputElement>) => {
            return (
                <TextMask
                    ref={(ref) => {
                        return ref;
                    }}
                    mask={props.mask}
                    guide={false}
                    placeholderChar={"_"}
                    {...props}
                />
            );
        }
    );

    return (
        <div className="application-modal_step" key={0}>
            <TextField
                {...register("appliactionType")}
                name="appliactionType"
                inputProps={{ type: "hidden" }}
                defaultValue="artists"
                style={{ display: "none" }}
            />

            <FormControl className="application-modal_question">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            {...register("agreeRules", { required: true })}
                        />
                    }
                    label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                    sx={{
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
                {errors.agreeRules && (
                    <FormHelperText error>Обязательное поле</FormHelperText>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Твой телефон с системой:
                </span>
                <Controller
                    name="applicationDirection"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="Android"
                                control={<Radio size="small" />}
                                label="Android"
                            />
                            <FormControlLabel
                                value="iOS"
                                control={<Radio size="small" />}
                                label="iOS"
                            />
                        </RadioGroup>
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Имя
                </span>
                <TextField
                    size="small"
                    {...register("fullName", { required: true })}
                    error={!!errors.fullName}
                    helperText={errors.fullName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Фамилия
                </span>
                <TextField
                    size="small"
                    {...register("fullName", { required: true })}
                    error={!!errors.fullName}
                    helperText={errors.fullName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Email адрес (для iOS - почта от iCloud):
                </span>
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            type="email"
                            {...register("contactEmail", { required: true })}
                            error={!!errors.contactEmail}
                            helperText={
                                errors.contactEmail && "Обязательное поле"
                            }
                            InputProps={{
                                // eslint-disable-next-line
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                    mask: [
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                    ],
                                },
                            }}
                            placeholder="your@mail.by"
                        />
                    )}
                />
            </FormControl>
        </div>
    );
};

export default StepOne;
