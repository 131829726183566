import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";

const StepTwo = ({ register, errors, control, selectedFiles, handleFileSelect }) => {
    return (
        <div className="application-modal_step" key={2}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка или ник в Telegram:
                </span>
                <TextField
                    size="small"
                    {...register("contactTelegram", { required: true })}
                    error={!!errors.contactTelegram}
                    helperText={errors.contactTelegram && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            {...register("agreeRules", { required: true })}
                        />
                    }
                    label="Я даю согласие на обработку персональных данных в системе билетного оператора Ticketok."
                    sx={{
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
                {errors.agreeRules && (
                    <FormHelperText error>Обязательное поле</FormHelperText>
                )}
            </FormControl>
        </div>
    );
};

export default StepTwo;
