import { FormControl, TextField } from "@mui/material";

const StepTwo = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={2}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка, ник в Telegram:
                </span>
                <TextField
                    size="small"
                    {...register("cv", { required: true })}
                    error={!!errors.city}
                    helperText={errors.city && "Обязательное поле"}
                />
            </FormControl>
        </div>
    );
};

export default StepTwo;
