import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Lottie from 'lottie-react';

import zoomIn from '../zoom.json';

const ModalWithPanZoom = ({ isOpen, onClose, imageUrl }) => {
  const isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed

  const dialogClassName = isMobile ? 'modal-dialog-fullscreen' : '';

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" className={dialogClassName}>
      <DialogContent>
        <TransformWrapper>
          <TransformComponent>
            <div className="modal-route">
              <Lottie animationData={zoomIn} loop={false} className="zoom-animation"/>
              <img src={imageUrl} alt="test" />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </DialogContent>
      
    </Dialog>
  );
};

export default ModalWithPanZoom;
