import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import PageTitle from "../../Components/pageTitle";
import "./style.scss";

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ru from 'react-phone-input-2/lang/ru.json';

type Inputs = {
    email: string;
    phone: string;
    ticketCategories: string[]; // Changed to array
    comment: string;
    agree: boolean;
};

const Returns = () => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        getValues,
    } = useForm<Inputs>({
        defaultValues: {
            ticketCategories: [], // Initialize ticketCategories as an empty array
        },
    });

    const [stateForm, setStateForm] = useState({
        loader: false,
        isFinal: false,
        message: "",
    });

    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const options = [
        { label: "Стандарт 2 дня", value: "Стандарт 2 дня" },
        { label: "VIP 2 дня", value: "VIP 2 дня" },
        { label: "Кемпинг 4 дня", value: "Кемпинг 4 дня" },
        { label: "Глэмпинг 4 дня", value: "Глэмпинг 4 дня" },
        { label: "Глэмпинг comfort 4 дня", value: "Глэмпинг comfort 4 дня" },
        { label: "Глэмпинг luxury 4 дня", value: "Глэмпинг luxury 4 дня" },
        { label: "Авто garage 4 дня", value: "Авто garage 4 дня" },
        { label: "Авто camper 4 дня", value: "Авто camper 4 дня" },
        { label: "Garage camp 4 дня", value: "Garage camp 4 дня" },
    ];

    const restrictedCategories = [
        "Кемпинг 4 дня",
        "Глэмпинг 4 дня",
        "Глэмпинг comfort 4 дня",
        "Глэмпинг luxury 4 дня",
        "Авто garage 4 дня",
        "Авто camper 4 дня",
        "Garage camp 4 дня"
    ];

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const dataSend = new FormData();
        dataSend.append("data", JSON.stringify(data));

        setStateForm({
            ...stateForm,
            loader: true,
        });

        const config = {
            method: "post",
            url: "https://vivabraslav.by/prod-api/index.php",
            data: dataSend,
        };

        axios(config)
            .then(function (response) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: response.data.message_user,
                });
                reset();
            })
            .catch(function (error) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: error.message_user,
                });
            });
    };

    const handleCloseModalFinal = () => {
        setStateForm({
            ...stateForm,
            isFinal: false,
        });
    };

    const handleCloseErrorDialog = () => {
        setErrorDialogOpen(false);
        setValue("ticketCategories", []); // Reset the selected category
    };

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        const selectedCategory = event.target.value;
        if (restrictedCategories.includes(selectedCategory)) {
            setErrorDialogOpen(true);
        } else {
            setValue("ticketCategories", [selectedCategory]); // Set the selected category as an array with one element
        }
    };

    return (
        <>
            <PageTitle title="Возврат билетов" subtitle="" />
            <div className="returns">
                <div className="returns_container">
                    <div className="returns_wrapper">
                        <h1 className="returns_title">Информация</h1>
                        <span className="returns_info">
                            <b>
                            Билеты категорий «Стандарт 2 дня» или «VIP 2 дня». 
                            </b>
                            {" "}
                            Обращаем Ваше внимание, что вернуть билеты на фестиваль можно не позднее, чем за 1 календарный день до дня проведения мероприятия (иной срок для подачи заявки на возврат может быть предусмотрен законодательством). При этом возврат производится в порядке и на условиях, установленных статьей 217 Кодекса Республики Беларусь о культуре. Возврат денежных средств осуществляется в течение 7 дней со дня подачи заявки о возврате (иной срок для возврата может быть предусмотрен законодательством).
                        </span>
                        <span className="returns_info">
                            <b>
                                Билеты категорий «Кемпинг 4 дня», «Авто garage 4
                                дня», «Авто camper 4 дня», «Глэмпинг Comfort 4
                                дня» и «Глэмпинг Luxury 4 дня».{" "}
                            </b>
                            Возврат денежных средств в случае отказа от услуги, указанной в билете, осуществляется в течение 14 дней со дня предъявления соответствующего требования на основании договора возмездного оказания услуг, в соответствии со ст. 38 Закона о защите прав потребителей. В стоимость услуги может входить плата за работу сервиса. Плата за работу сервиса не подлежит возврату, так как услуга по продаже билета оказана.
                        </span>
                        {/* <h1 className="returns_title">Я хочу вернуть билет</h1> */}
                        {/* <span
                            className="returns_info"
                            style={{ marginBottom: "10px" }}>
                            Выберите категорию билета
                        </span>
                        <form
                            className="returns_form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="ticketCategories"
                                    control={control}
                                    rules={{
                                        required:
                                            "Выберите категорию билета",
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={field.value[0] || ""}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return "Выберите категорию";
                                                }
                                                return selected;
                                            }}
                                            onChange={handleCategoryChange}
                                            size="small"
                                            error={!!errors.ticketCategories}
                                            style={{
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                            }}>
                                            {options.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ticketCategories && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Выберите категорию билета
                                    </span>
                                )}
                            </FormControl>
                            <TextField
                                size="small"
                                autoComplete="off"
                                placeholder="Адрес электронной почты"
                                fullWidth
                                {...register("email", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message:
                                            "Введите корректный email адрес",
                                    },
                                })}
                                error={!!errors.email}
                                helperText={
                                    errors.email ? errors.email.message : ""
                                }
                                style={{ marginBottom: "10px" }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "white !important",
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                                type="email"
                            />
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field }) => (
                                    <ReactPhoneInput
                                        {...register("phone", { required: true })}
                                        {...field}
                                        value={field.value} // Set the default country value
                                        country='by'
                                        // onlyCountries={['by', 'ru', 'lt', 'lv', 'pl', 'ua']}
                                        localization={ru}
                                        inputProps={{
                                            name: 'phone',
                        
                                            placeholder: 'Начните писать тут...',
                                            required: true,
                                            autoformat: true,
                                        }}
                                        onChange={(value) => field.onChange(value)} // Update the field value on change
                                    />
                                    )}
                                /> 
                                {errors.phone && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>
                            <TextField
                                size="small"
                                placeholder="Укажите причину возврата"
                                multiline
                                rows={4}
                                fullWidth
                                {...register("comment", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                })}
                                error={!!errors.comment}
                                helperText={
                                    errors.comment ? errors.comment.message : ""
                                }
                                style={{ marginBottom: "10px" }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "white !important",
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                            />
                            <FormControl className="application-modal_question">
                                <FormControlLabel
                                        control={
                                        <Checkbox
                                            size="small"
                                            {...register('agree', { required: true })}
                                            sx={{
                                                color: "#fff", // Цвет рамки и галочки в неактивном состоянии
                                                '&.Mui-checked': {
                                                  color: "#fff", // Цвет рамки и галочки в активном состоянии
                                                }
                                              }}
                                        />
                                        }
                                        label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                            fontSize: "12px",
                                            color: "#fff"
                                            },
                                        }}
                                    />
                                {errors.agree && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>
                            <button type="submit" className="returns_form_send">
                                <div
                                    className="loader"
                                    style={
                                        stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }></div>
                                <span
                                    style={
                                        !stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }>
                                    Отправить
                                </span>
                            </button>
                        </form> */}
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={stateForm.loader}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Dialog
                            open={stateForm.isFinal}
                            onClose={handleCloseModalFinal}
                            
                        >
                            <DialogContent className="returns_modal">
                                <DialogContentText id="alert-dialog-description">
                                    {stateForm.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseModalFinal}
                                    autoFocus>
                                    Закрыть
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={errorDialogOpen}
                            onClose={handleCloseErrorDialog}>
                            <DialogContent>
                            <DialogContentText>
                                    Обращаем Ваше внимание, согласно публичным договорам возмездного оказания услуг, размещенным на сайте vivabraslav.by, вернуть билет(ы): <br/><br/><b>"Кемпинг 4 дня",<br/>"Глэмпинг 4 дня",<br/> "Глэмпинг comfort 4 дня",<br/> "Глэмпинг luxury 4 дня",<br/> "Авто garage 4 дня",<br/> "Авто camper 4 дня",<br/> "Garage camp 4 дня"<br/><br/></b> можно не позднее, чем за 10 (десять) календарных дней до начала оказания услуг.<br/><b>В связи с этим, с 15 июля 2024г. возврат билетов данных категорий осуществляться не будет.</b>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseErrorDialog} autoFocus>
                                    Закрыть
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Returns;
