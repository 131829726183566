import './style.scss';

type NavType = {
    stepInfo: {
        actual: number,
        max: number
    },
    stepPrev: () => void
}


const NavigationButtons = ({stepInfo, stepPrev}: NavType) => {
        const { actual, max } = stepInfo;

        if(actual === max) {
            return(
                <>
                    <div className="btns">
                        <input className="btn-prev" type="button" value="Назад" onClick={() => {stepPrev()}}/>
                        <input className="btn-next" type="submit" value="Отправить" />
                    </div>
                </>
                
            );
        } else if (actual < max && actual !== 0) {
            return(
                <div className="btns">
                    <input className="btn-prev" type="button" value="Назад" onClick={() => {stepPrev()}}/>
                    <input className="btn-next" type="submit" value="Далее" />
                </div>
            );
        } else if (actual < max) {
            return(
                <div className="btns">
                    <input className="btn-prev" type="button" value="Назад" onClick={() => {stepPrev()}}/>
                    <input className="btn-next" type="submit" value="Далее" />
                </div>
            );
        }

}

export default NavigationButtons;