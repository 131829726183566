import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import TextMask, { TextMaskProps, MaskedInput } from "react-text-mask";

const StepOne = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={0}>
            <TextField
                {...register("type")}
                name="type"
                inputProps={{ type: "hidden" }}
                defaultValue="obmenTicketok"
                style={{ display: "none" }}
            />

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Твой телефон с системой:
                </span>
                <Controller
                    name="typeDevice"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="android"
                                control={<Radio size="small" />}
                                label="Android"
                            />
                            <FormControlLabel
                                value="IOS"
                                control={<Radio size="small" />}
                                label="iOS"
                            />
                        </RadioGroup>
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Имя
                </span>
                <TextField
                    size="small"
                    {...register("firstName", { required: true })}
                    error={!!errors.firstName}
                    helperText={errors.firstName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Фамилия
                </span>
                <TextField
                    size="small"
                    {...register("lastName", { required: true })}
                    error={!!errors.lastName}
                    helperText={errors.lastName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Email адрес:
                </span>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            type="email"
                            {...register("contactEmail", { required: true })}
                            error={!!errors.contactEmail}
                            helperText={
                                errors.contactEmail && "Обязательное поле"
                            }
                            InputProps={{
                                // eslint-disable-next-line
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                    mask: [
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                    ],
                                },
                            }}
                            placeholder="your@mail.by"
                        />
                    )}
                />
            </FormControl>
        </div>
    );
};

export default StepOne;
