import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { Pagination, Navigation, Autoplay } from "swiper";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
    closeModalMore,
    openModalBuyFromMore,
} from "../../../redux/slices/ticketsModalSlice";

const ModalMore = () => {
    const [moreText, setMoreText] = useState(false);

    const { selected } = useAppSelector(
        (state: RootState) => state.ticketsModalStore
    );
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setMoreText(false);
        dispatch(closeModalMore());
    };

    const toggleMoreText = () => {
        setMoreText(!moreText); // Переключаем состояние для отображения всего текста
    };

    const {
        id,
        title,
        photo_modal = [],
        color,
        status,
        description_modal,
        attention = [],
        footnotes = [],
        operator,
        event_id,
        description_list,
        terms = null,
    } = selected;

    const getBtn = (status: string) => {
        switch (status) {
            case "sold":
            case "soldout":
                return <div className="modal-more_sold">Распродано</div>;
            case "soon":
                return <div className="modal-more_sold">Скоро</div>;
            default:
                if (operator === "url") {
                    return (
                        <a
                            href={event_id}
                            target="_blank"
                            rel="noreferrer"
                            className="modal-more_btn shine">
                            Купить
                        </a>
                    );
                }
                return (
                    <div
                        className="modal-more_btn shine"
                        onClick={() => {
                            setMoreText(false);
                            dispatch(openModalBuyFromMore());
                        }}>
                        Купить
                    </div>
                );
        }
    };

    const getSlider = () => {
        if (photo_modal.length) {
            if (photo_modal.length == 1) {
                return (
                    <div className="modal-more_gallery">
                        <Swiper className="modal-more_slider">
                            {photo_modal.map((slide: string, index) => {
                                return (
                                    <SwiperSlide
                                        key={"slide_" + id + "_" + index}
                                        className="modal-more_slide">
                                        <img
                                            src={
                                                "https://vivabraslav.by/" +
                                                slide
                                            }
                                            className="modal-more_slide_img"
                                            alt="swiper item"
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        {getBtn(status)}
                    </div>
                );
            } else if (photo_modal.length > 1) {
                return (
                    <div className="modal-more_gallery">
                        <Swiper
                            pagination={true}
                            navigation={true}
                            modules={[Pagination, Autoplay, Navigation]}
                            className="modal-more_slider"
                            loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}>
                            {photo_modal.map((slide: string, index) => {
                                return (
                                    <SwiperSlide
                                        key={"slide_" + id + "_" + index}
                                        className="modal-more_slide">
                                        <img
                                            src={
                                                "https://vivabraslav.by/" +
                                                slide
                                            }
                                            className="modal-more_slide_img"
                                            alt="swiper item"
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        {getBtn(status)}
                    </div>
                );
            }
        } else {
            return getBtn(status);
        }
    };

    return (
        <div className="modal-more">
            <div className="modal-more_container">
                <div className="modal-more_wrapper">
                    <div
                        className="modal-more_header"
                        style={{ background: "#" + color }}>
                        <div className="modal-more_wave">
                            <svg
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle
                                    cx="21"
                                    cy="21"
                                    r="21"
                                    fill={"#" + color}
                                />
                            </svg>
                        </div>
                        <div
                            className="modal-more_close"
                            onClick={() => {
                                handleClose();
                            }}></div>
                    </div>
                    <div
                        className="modal-more_scroll"
                        style={
                            moreText
                                ? {}
                                : {
                                      overflow: "hidden",
                                  }
                        }>
                        <h1 className="modal-more_title">Билет "{title}"</h1>
                        <div
                            className="modal-more_drop-down"
                            style={
                                moreText
                                    ? {
                                          maxHeight: "1000px",
                                      }
                                    : {
                                          transition: "0s all",
                                      }
                            }>
                            <span className="modal-more_description">
                                {description_modal}
                            </span>

                            {description_list
                                ? description_list.map(
                                      (description_list_item, index1) => (
                                          <>
                                              <span className="modal-more_incldue-text">
                                                  {description_list_item.text}
                                              </span>
                                              <ul
                                                  className="modal-more_include"
                                                  key={"list_" + index1}>
                                                  {description_list_item.items.map(
                                                      (item, index2) => (
                                                          <li
                                                              key={
                                                                  "include_" +
                                                                  index1 +
                                                                  "_" +
                                                                  index2
                                                              }>
                                                              {item}
                                                          </li>
                                                      )
                                                  )}
                                              </ul>
                                          </>
                                      )
                                  )
                                : null}
                            {footnotes.length ? (
                                <ul className="modal-more_footnotes">
                                    {footnotes.map((item: string, index) => (
                                        <li key={"footnotes_" + index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                            {attention.length ? (
                                <ul className="modal-more_attention">
                                    {attention.map((item: string, index) => (
                                        <li key={"attention_" + index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                            {terms && terms["friendly_text"] ? (
                                <p className="modal-more_description" dangerouslySetInnerHTML={{ __html: terms["friendly_text"] }}></p>
                            ) : null}
                        </div>
                        <div
                            className="modal-more_open-more"
                            onClick={toggleMoreText}
                            style={
                                moreText
                                    ? {
                                          height: 0,
                                          opacity: 0,
                                          pointerEvents: "none",
                                      }
                                    : {
                                          maxHeight: "1000px", 
                                          transition:
                                              "max-height 0.7s ease-out, opacity 0.3s, height 0.3s",
                                      }
                            }>
                            <span className="modal-more_open-more_text">
                                Показать полностью
                            </span>
                        </div>
                        {getSlider()}
                    </div>
                </div>
            </div>
            <div
                className="modal-more_bg"
                onClick={() => {
                    handleClose();
                }}></div>
        </div>
    );
};

export default ModalMore;
