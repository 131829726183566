import iFrame from './Проект_договора_комиссии_по_продаже_билетов_через_сайт_23_11_2022';
import './style.scss';
import PageTitle from '../../Components/pageTitle';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Privacy = () => {
    
    const pdfUrl = "https://vivabraslav.by/docs/policy.pdf"; // Replace with your PDF URL
    const uniquePdfUrl = `${pdfUrl}?t=${new Date().getTime()}`;
    const { pathname } = useLocation();

    const [showIframe, setShowIframe] = useState(false);

    useEffect(() => {
        // Сброс состояния при каждом изменении маршрута
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        setShowIframe(false);

        const iframe = document.querySelector('iframe');
        if (iframe) {
            iframe.src = `https://docs.google.com/document/d/e/2PACX-1vR4VvRYkqYrJts6Lpw5lBvpJpgN1xYDOTIwk5qkzTswWvG0VQVjHAnpNNxstHdCol3svk-qUQLVtnyO/pub?embedded=true`; // Принудительное обновление src, чтобы перезагрузить iframe
        }

        // Включение отображения iframe после небольшой задержки
        const timer = setTimeout(() => setShowIframe(true), 1000); // Задержка в 100 мс

        return () => clearTimeout(timer);
    }, [pathname]);
    
    return (
        <>
            <PageTitle
                title="Публичный договор"
                subtitle=""
            />
            <div className="contract">
                <div className="contract_container">
                    <div className="contract_wrapper">
                        <h1 className="contract_title">Информация</h1>
                        <iframe
                            src={`https://docs.google.com/document/d/e/2PACX-1vR4VvRYkqYrJts6Lpw5lBvpJpgN1xYDOTIwk5qkzTswWvG0VQVjHAnpNNxstHdCol3svk-qUQLVtnyO/pub?embedded=true`}
                            style={{ width: "90%", height: "900px" }}
                            frameBorder="0"></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}



export default Privacy;