import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../api';

export interface UserState {
  program: string[],
  isOpenModal: boolean,
  selectedArtist: any
}

const initialState: UserState = {
  program: [],
  isOpenModal: false,
  selectedArtist: {
    title: '',
    short_description: '',
    description: '',
    photo: '',
    organizer: ''
  }
};

const applicationsSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<number>) {
      state.selectedArtist = action.payload;
      state.isOpenModal = true;
      state.selectedArtist = state.program['artists'][action.payload];
    },
    closeModal(state) {
      state.isOpenModal = false;
    }
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.getProgram.matchFulfilled,
      (state, { payload }) => {
        state.program = payload;
      }
    )
  },
});

export const {openModal, closeModal} = applicationsSlice.actions;
export default applicationsSlice.reducer;