import React, { useState } from "react";
import axios from "axios";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepTwoChild from "./StepTwoChild";
import StepThree from "./StepThree";
import ProgresBar from "../FormComponents/ProgresBar";
import NavigationButtons from "../FormComponents/NavigationButtons";
import StepLoading from "../FormComponents/StepLoading";
import StepSuccess from "../FormComponents/StepSuccess";
import StepWrong from "../FormComponents/StepWrong";
import FormHeader from "../FormComponents/FormHeader";
import PaymentStep from "./PaymentStep";

import '../style_form.scss';

interface Inputs {
    appliactionType: string,
    type: string,
    contactPerson: string,
    contactBirthday: string,
    сontactSex: string,
    childPerson: string,
    childBirthday: string,
    childSex: string,
    contactPhone: string,
    contactEmail: string,
    contactPhoneEmergency: string,
    agreeRules: boolean,
    countryLive: string
}

const defaultValues: Inputs = {
    appliactionType: "water",
    type: '',
    contactPerson: '',
    contactBirthday: '',
    сontactSex: '',
    childPerson: '',
    childBirthday: '',
    childSex: '',
    contactPhone: '',
    contactEmail: '',
    contactPhoneEmergency: '',
    agreeRules: false,
    countryLive: ''
}; 

const WaterRunForm = () => {
    const dispatch = useAppDispatch();

    const applicationName = "Заявка на участие в забеге Viva Braslav 2024";

    const methods = useForm<Inputs>({ defaultValues });
    const { control, register, handleSubmit, setValue, setError, getValues, formState: { dirtyFields, errors } } = methods;

    const [step, setStep] = useState({
        actual: 0,
        max: 3
    });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://vivabraslav.by/prod-api/applications.php',
            data: dataSend
        };

        const sendToServer = () => {
            dataSend.append('data', JSON.stringify(data));
            dataSend.append('g-recaptcha-response', "test");

            axios(config)
                .then(function (response) {
                    const responseData = response.data;
                    if (responseData.response === "ok") {
                        setStep({ ...step, actual: 4 });
                    } else {
                        setStep({ ...step, actual: 7 });
                    }
                })
                .catch(function () {
                    setStep({ ...step, actual: 7 });
                });
        }

        if (step.actual === step.max || step.actual === 7 || step.actual === 6) {
            setStep({ ...step, actual: 5 });
            sendToServer();
        } else {
            const isFamilySport = getValues("type") == "sport-1";
            if(!isFamilySport) {
                if(step.actual == 1) {
                    setStep({ ...step, actual: 3 });
                } else {
                    setStep({ ...step, actual: step.actual + 1 });
                }
            } else {
                setStep({ ...step, actual: step.actual + 1 });
            }
            
            
            console.log(step);
        }
    }

    const changeStep = () => {
        
        
        if (step.actual > 0) {
            // setStep({
            //     ...step,
            //     actual: step.actual - 1,
            // });

            const isFamilySport = getValues("type") == "sport-1";
            if(!isFamilySport) {
                if(step.actual == 3) {
                    setStep({ ...step, actual: 1 });
                } else {
                    setStep({ ...step, actual: step.actual - 1 });
                }
            } else {
                setStep({ ...step, actual: step.actual - 1 });
            }

        } else {
            dispatch(openModal(6));
        }
        console.log(step);
    };

    const getStep = () => {
        switch (step.actual) {
            case 0:
                return (
                    <StepOne
                        register={register} 
                        errors={errors}
                        control={control}
                        handleSet={setValue}
                        fields={dirtyFields}
                        setError={setError}
                    />
                );
            case 1:
                return (
                    <StepTwo 
                        register={register} 
                        errors={errors}
                        control={control}
                        handleSet={setValue}
                        fields={dirtyFields}
                        setError={setError}
                    />
                );
            case 2:
                return (
                    <StepTwoChild 
                        register={register} 
                        errors={errors}
                        control={control}
                        handleSet={setValue}
                        fields={dirtyFields}
                        setError={setError}
                    />
                );
            case 3:
                return (
                    <StepThree 
                        register={register} 
                        errors={errors}
                        control={control}
                        handleSet={setValue}
                        fields={dirtyFields}
                        setError={setError}
                    />
                );
                // case 0:
                // return (
                //     <PaymentStep 
                        
                //     />
                // );
            case 4:
                return (
                    <div className="application-modal_final">
                        <span className="application-modal_final_title">Спасибо!</span>
                        <span className="application-modal_final_text">Заявка будет принята только после получения нами оплаты.</span>
                        <span className="application-modal_final_text">На Вашу почту была выслана информация по поводу оплаты.</span>
                    </div>
                );

            case 5:
                return <StepLoading key={6}/>;
            case 6:
                return <StepSuccess key={7}/>;
            case 7:
                return <StepWrong key={8}/>;
        }
    }

    return (
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            <FormProvider {...methods}>
                <form className="application-modal_steps" onSubmit={handleSubmit(onSubmit)}>
                    {getStep()}
                    <div className="application-modal_navigation">
                        <NavigationButtons 
                            stepInfo={step} 
                            stepPrev={changeStep} 
                        />
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}

export default WaterRunForm;
