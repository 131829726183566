import './style.scss';
import closeIcon from '../../../img/tickets/close-modal.svg';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { closeModalBuy, openConfirm, openFinalTicketok } from '../../../redux/slices/ticketsModalSlice';

const ModalPayment = () => {

    const [testUrl, setConstUrl] = useState("");
    const hostname = "http://localhost:3002";
    // const hostname = "https://store.ticketok.by";
    const { selected, isOpenModalBuy, isOpenConfirm } = useAppSelector((state: RootState) => state.ticketsModalStore);
    const dispatch = useAppDispatch();

    const [isFinalTicketOpened, setIsFinalTicketOpened] = useState(false);

    function openBeGateway (event: MessageEvent) {
        const data = event.data;
        if(data.event === "bePaidPayment") {
            const url = new URL(data.link);
            //setConstUrl(data.link);
            dispatch(closeModalBuy());
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            new window.BeGateway({
                token: data.token, 
                checkout_url: url.origin,
                closeWidget: function(status) {
                    if(status === 'successful') {
                        setConstUrl(hostname + '/final?email='+ data.email+'&token=' + data.session_key);
                        dispatch(openFinalTicketok());
                        console.log(hostname + '/final?email='+ data.email+'&token=' + data.session_key);
                    }
                  }
            }).createWidget();
        }
    }

    useEffect(() => {
        window.addEventListener("message", openBeGateway); 
        return () => {
            window.removeEventListener("message", openBeGateway);
        }    
        // eslint-disable-next-line
    }, []);


    const handleOpenConfirm = () => {
        dispatch(openConfirm());
    }

    const handleCloseModay = () => {
        dispatch(closeModalBuy());
        setConstUrl("");
    }


    // useEffect(() => {
    //     if (isOpenModalBuy && !isFinalTicketOpened) {
    //         const timer = setTimeout(() => {
    //             dispatch(closeModalBuy());

    //             //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //             //@ts-ignore
    //             new window.BeGateway({
    //                 token: "602f7fbaf00aa60c511f654aafd519bd623984cc244a616e13e6c601927a583f",
    //                 checkout_url: new URL("https://checkout.bepaid.by/widget/hpp.html?token=3602f7fbaf00aa60c511f654aafd519bd623984cc244a616e13e6c601927a583f").origin,
    //                 closeWidget: function(status) {
    //                     setConstUrl('https://store.ticketok.by/final?email=DEMO&token=DEMO');
    //                     dispatch(openFinalTicketok());
    //                     setIsFinalTicketOpened(true); // Set state to true to prevent re-triggering

    //                     // Update the URL with the status
    //                     const currentUrl = new URL(window.location.href);
    //                     currentUrl.searchParams.set('status', 'successful');
    //                     window.history.pushState({}, '', currentUrl.toString());
    //                 }
    //             }).createWidget();

    //         }, 5000);

    //         return () => clearTimeout(timer);
    //     }
    // }, [isOpenModalBuy, isFinalTicketOpened, dispatch]);

    if(!selected.id && !isOpenModalBuy) {
        return (
            <div className="modal-payment">
                <div className="modal-payment_container">
                    <div className="modal-payment_wrapper">
                    </div>
                </div>
            </div>
        );  
    }

    const { ticket_id, event_id, operator } = selected;

    switch (operator) {
        case 'ticketok':
            if(isOpenModalBuy) {
                if(testUrl) {
                    return (
                        <div className="modal-payment">
                            <div className="modal-payment_container">
                                <div className="modal-payment_wrapper">
                                    <div className="modal-payment_close" onClick={() => handleOpenConfirm()}>
                                        <img src={closeIcon} alt="X" className="modal-payment_close_icon" />
                                    </div>
                                    <div className="test"></div>
                                    <iframe title="Ticketok Modal" src={testUrl}></iframe>
                                </div>
                                <div className="modal-payment_close-window" style={isOpenConfirm ? {opacity: 1, pointerEvents: 'all'} : {}}>
                                    <span className="modal-payment_close-window_text">Вы действительно хотите закрыть окно?</span>
                                    <div className="modal-payment_close-window_btns">
                                        <div className="modal-payment_close-window_cancel" onClick={() => handleOpenConfirm()}>Отменить</div>
                                        <div className="modal-payment_close-window_close" onClick={()=>handleCloseModay()}>Закрыть</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-payment_bg" onClick={() => handleOpenConfirm()}></div>
                        </div>
                    ); 
                } else {
                    return (
                        <div className="modal-payment">
                            <div className="modal-payment_container">
                                <div className="modal-payment_wrapper">
                                    <div className="modal-payment_close" onClick={() => handleOpenConfirm()}>
                                        <img src={closeIcon} alt="X" className="modal-payment_close_icon" />
                                    </div>
                                    <div className="test"></div>
                                    <iframe title="Viva Braslav Modal" src={hostname + "/widget/?ticket_id=1&event_id=1"}></iframe>
                                </div>
                                <div className="modal-payment_close-window" style={isOpenConfirm ? {opacity: 1, pointerEvents: 'all'} : {}}>
                                    <span className="modal-payment_close-window_text">Вы действительно хотите закрыть окно?</span>
                                    <div className="modal-payment_close-window_btns">
                                        <div className="modal-payment_close-window_cancel" onClick={() => handleOpenConfirm()}>Отменить</div>
                                        <div className="modal-payment_close-window_close" onClick={()=>handleCloseModay()}>Закрыть</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-payment_bg" onClick={() => handleOpenConfirm()}></div>
                        </div>
                    ); 
                }
            } else {
                return (
                    <div className="modal-payment">
                        <div className="modal-payment_container">
                            <div className="modal-payment_wrapper">
                            </div>
                        </div>
                    </div>
                );  
            }            
    }
}

export default ModalPayment;