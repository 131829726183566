import { useState } from "react";
import "./style.scss";

import Ticket from "../ticket";
import Lottie from "lottie-react";

import zoomIn from "../../howToGet/zoom.json";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface DetailsType {
    available: boolean;
    title: string;
    description: string;
    icon: string;
    tickets: string[];
    rules: string;
    img: any;
}

const Details = ({
    available,
    title,
    description,
    icon,
    tickets,
    rules,
    img,
}: DetailsType) => {
    // eslint-disable-next-line
    const [isOpen, setIsOpen] = useState(true);
    // const [maxHeight, setMaxHeight] = useState(0);
    // const height = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        //if (height.current !== null) {
        // console.log(height);
        //setIsOpen(!isOpen);
        // const heightElem = height.current.offsetHeight;
        // setMaxHeight(heightElem);
        //}
    };

    return (
        <div
            className={`details ${isOpen ? "open" : ""}`}
            onClick={() => handleOpen()}>
            <div
                className="details_summary"
                style={isOpen ? { borderRadius: "16px 16px 0px 0px" } : {}}>
                <img
                    src={icon}
                    alt="Кемпинг"
                    className="details_summary_icon"
                />
                <span className="details_summary_title">{title}</span>
            </div>
            <div
                className={`details_content ${isOpen ? "open" : ""}`}
                style={isOpen ? { maxHeight: "1000px" } : { maxHeight: 0 }}>
                <p
                    className="details_content_text"
                    dangerouslySetInnerHTML={{ __html: description }}></p>
                {rules && (
                    <span className="details_info">
                        Не забудь ознакомиться с правилами посещения территории{" "}
                         —{" "}
                        <a
                            href={rules}
                            rel="noreferrer"
                            target="_blank"
                            className="details_rules">
                            здесь
                        </a>
                        .
                    </span>
                )}
                {tickets.map((item, index) => {
                    return <Ticket key={index} info={item} categoryId={2} />;
                })}
                {img && (
                    <TransformWrapper>
                        <TransformComponent>
                            <div className="modal-route">
                                <Lottie
                                    animationData={zoomIn}
                                    loop={false}
                                    className="zoom-animation"
                                />
                                <img src={img} alt="test" />
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                )}
            </div>
        </div>
    );
};

export default Details;
