import TorgForm from "../TorgForm";

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

import { useAppDispatch } from '../../../app/hooks';
import { closeModal } from '../../../redux/slices/applicationsSlice';

import WebVolForm from "../WebVolForm";
import WebmanagerForm from "../WebManagerForm";


const FormModal = () => {

  const { selectedForm } = useAppSelector((state: RootState) => state.applicationsStore);
  const dispatch = useAppDispatch();

  if (!selectedForm) {
    return(
      <div className="application-modal">
          <div className="application-modal_container">
          </div>
      </div>
    )
  }

    const returnForm = () => {
        switch (selectedForm) {
            case 11:
                return(<WebVolForm/>);
        
            case 12: 
                return(<WebmanagerForm />);  
 
                           
            default:
                break;
        }
    }
    
    return(
        <div className="application-modal">
            <div className="application-modal_container">
                {returnForm()}
            </div>
            <div className="application-modal_bg" onClick={()=>dispatch(closeModal())}></div>
        </div>
    );
}

export default FormModal;