import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Link,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ru from 'react-phone-input-2/lang/ru.json';


import TextMask, { TextMaskProps } from "react-text-mask";

import { MaskedInput } from "react-text-mask";
import { openModal } from "../../../../redux/slices/applicationsSlice";

import ContentPasteIcon from '@mui/icons-material/ContentPaste';


const StepTwo = ({
    register,
    errors,
    control,
    handleSet,
    fields,
    setError,
}) => {

    return (
        <div className="application-modal_step" key={0}>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Название команды:
                </span>
                <TextField
                    size="small"
                    {...register("teamName", { required: true })}
                    error={!!errors.teamName}
                    helperText={errors.teamName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Количество участников:
                </span>
                <TextField
                    size="small"
                    {...register("teamCount", { required: true })}
                    error={!!errors.teamCount}
                    helperText={errors.teamCount && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Город:
                </span>
                <TextField
                    size="small"
                    {...register("teamPlace", { required: true })}
                    error={!!errors.teamPlace}
                    helperText={errors.teamPlace && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Комментарий:
                </span>
                <TextField
                    size="small"
                    {...register("comment")}
                    error={!!errors.comment}
                    helperText={errors.comment && "Обязательное поле"}
                />
            </FormControl>
            
            <FormControl className="application-modal_question">
                <FormControlLabel
                        control={
                        <Checkbox
                            size="small"
                            {...register('agreeRules', { required: true })}
                        />
                        }
                        label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                        sx={{
                            '& .MuiFormControlLabel-label': {
                            fontSize: "12px"
                            },
                        }}
                    />
                {errors.agreeRules && <FormHelperText error>Необходимо Ваше согласие</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default StepTwo;
