import './style.scss';

type ProgresType = {
    stepInfo: {
        actual: number,
        max: number
    },
}

const ProgresBar = ({stepInfo}: ProgresType) => {
    const { actual, max } = stepInfo;
    const procent = ((actual/max)*100).toFixed(0);

    if(actual <= max) {
        return(
            <div className="progress">
                <span className="progress_text" style={Number(procent) > 50 ? {color: '#fff'} : {}}>{procent + '%'}</span>
                <div className="progress_active" style={{width: procent + '%'}}></div>
            </div>
        );
    } else {
        return(<></>);
    }
}

export default ProgresBar;