import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const StepTwo = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={1}>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Город проживания:</span>
                <TextField 
                    size="small" 
                    {...register('place')}
                    error={!!errors.place}
                    helperText={errors.place && "Обязательное поле"}
                />
            </FormControl>
            
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка на соц.сети:
                </span>
                <TextField
                    size="small"
                    {...register("messengerLink", { required: true })}
                    error={!!errors.messengerLink}
                    helperText={errors.messengerLink && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка на портфолио:
                </span>
                <TextField
                    size="small"
                    {...register("cvLink", { required: true })}
                    error={!!errors.cvLink}
                    helperText={errors.cvLink && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Опыт работы репортажной съемки:</span>
                <Controller
                    name="experienceReporter"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio size="small" />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio size="small" />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {errors.experienceReporter && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Опыт работы на Viva Braslav:</span>
                <Controller
                    name="experienceViva"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Да" control={<Radio size="small" />} label="Да" />
                            <FormControlLabel value="Нет" control={<Radio size="small" />} label="Нет" />
                        </RadioGroup>
                    )}
                />
                {errors.experienceViva && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

           
        </div>
    );
};

export default StepTwo;
