import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://vivabraslav.by/prod-api',
  }),
  
  endpoints: (builder) => ({
    // eslint-disable-next-line
    getTickets: builder.query<any, void>({
      query: () => '/tickets-data/data.json',
    }),
    getProgram: builder.query<any, void>({
      query: () => '/program/data.json',
    }),
    // eslint-disable-next-line
    getTicketsTest: builder.query<any, void>({
      query: () => '/tickets-data/data_test.json',
    }),
  }), 
});


export const { useGetTicketsQuery, useGetProgramQuery, useGetTicketsTestQuery } = api;