import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  selectedForm: number;
  isOpenModal: boolean;
}

const initialState: UserState = {
  selectedForm: null,
  isOpenModal: false
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<number>) {
      state.selectedForm = action.payload;
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    }
  },
});

export const {
  openModal,
  closeModal
} = applicationsSlice.actions;

export default applicationsSlice.reducer;