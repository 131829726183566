import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import './style.scss';

type Header = {
    title: string;
    subtitle: string;
}

const PageTitle = ({ title, subtitle }: Header) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);
    return(
        <div className="page-title">
            <div className="page-title_container">
                <div className="page-title_wrapper">
                    <h1 className="page-title_title">{title}</h1>
                    <h2 className="page-title_subtitle">{subtitle}</h2>
                </div>
            </div>
        </div>
    );
}

export default PageTitle;