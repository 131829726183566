import "../style_form.scss";
import FormHeader from "../FormComponents/FormHeader";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

const SportForm = () => {
    const applicationName = "";
    const dispatch = useAppDispatch();

    const getStart = () => {
        return (
            <div className="application-modal_step" key={0}>
                <Typography
                    variant="h6"
                    textAlign="center"
                    marginBottom="5px"
                    marginTop="25px">
                    Заявки на участие в спортивных мероприятиях
                </Typography>
                <Typography
                    variant="body1"
                    textAlign="center"
                    marginBottom="5px"
                    marginTop="15px">
                    Выберите вид спорта
                </Typography>
                <List>
                    
                    {/* <ListItem>
                        <ListItemButton onClick={() => dispatch(openModal(63))} selected={true} disabled={false}>
                            <ListItemIcon>
                                <DirectionsRunIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Забег Viva Braslav 2024"
                                secondary="Нажми сюда, чтобы заполнить заявку"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => dispatch(openModal(64))} selected={true} disabled={false}>
                            <ListItemIcon>
                                <GolfCourseIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Мини-гольф"
                                secondary="Нажми сюда, чтобы заполнить заявку"
                            />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem >
                        <ListItemButton
                            component="a"
                            href="https://vivabraslav.by/docs/2024/running.pdf"
                            target="_blank"
                            selected={true}>
                            <ListItemIcon>
                                <DirectionsRunIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Забег Viva Braslav 2024"
                                secondary="Положение о проведении"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem >
                        <ListItemButton
                            component="a"
                            href="https://vivabraslav.by/docs/2024/mini-golf.pdf"
                            target="_blank"
                            selected={true}>
                            <ListItemIcon>
                                <GolfCourseIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Мини-гольф"
                                secondary="Положение о проведении"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem >
                        <ListItemButton
                            component="a"
                            href="https://vivabraslav.by/docs/2024/streetball.pdf"
                            target="_blank"
                            selected={true}>
                            <ListItemIcon>
                                <SportsFootballIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Cтритбол"
                                secondary="Положение о проведении"
                            />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem>
                        <ListItemButton onClick={() => dispatch(openModal(61))} selected={true} disabled={true}>
                        
                            <ListItemIcon>
                                <SportsFootballIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Cтритбол"
                                secondary="Приём заявок закрыт"
                            />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem >
                        <ListItemButton
                            component="a"
                            href="https://vivabraslav.by/docs/2024/voleyball.pdf"
                            target="_blank"
                            selected={true}>
                            <ListItemIcon>
                                <SportsVolleyballIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Волейбол"
                                secondary="Положение о проведении"
                            />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem>
                        
                        <ListItemButton onClick={() => dispatch(openModal(62))} selected={true} disabled={true}>
                            <ListItemIcon>
                                <SportsVolleyballIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Волейбол"
                                secondary="Приём заявок закрыт"
                            />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </div>
        );
    };

    return (
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            {getStart()}
        </div>
    );
};

export default SportForm;
